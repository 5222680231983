import localforage from 'utils/localforage';
import { all, call, debounce, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { api } from 'utils/api';
import { actions } from 'app/slice';
import { actions as appActions } from 'app/slice';
import { States, UserInterface } from 'types';
import { appDataSelector } from 'app/selectors';
import {
  AddOrUpdateItemParams,
  AddOrUpdateListParams,
  FetchTechnicalVisitsParams,
  Resources,
  SearchTechnicalVisitsParams,
} from 'app/types';
import { dashboardPageSelector } from 'app/containers/DashboardPage/selectors';
import { updateOrAddToCollection } from 'utils/transform';
import { technicalVisit } from 'app/containers/TechnicalVisitPage/initialStates';
import { subDays, startOfToday } from 'date-fns';
import {
  getCacheableTechnicalVisitsIds,
  fetchImagesCollections,
} from './fetching';

export function* startCaching() {
  try {
    const { syncStatus } = yield select(appDataSelector);
    if (['awaiting', 'resuming'].includes(syncStatus.current)) {
      // yield put(actions.setSyncStatus('caching'));
      const technicalVisitsIds = yield getCacheableTechnicalVisitsIds();
      for (const id of technicalVisitsIds) {
        yield cacheTechnicalVisit(id);
      }
      // yield put(actions.setSyncStatus('awaiting'));
      yield put(actions.setLastMergingAt());

      // yield put(
      //   actions.setSyncStatus(
      //     syncStatus.previous && syncStatus.previous !== 'caching'
      //       ? syncStatus.previous
      //       : 'awaiting',
      //   ),
      // );
    }

    // if (technicalVisitsIds.length > 0) yield put(actions.setSyncStatus('merging'));
  } catch (e) {
    console.log(e);
    console.error('Unable to synchronize due to merging failure');
    throw e;
    //START PAUSING THEN RESUME
  }
}

export function* cacheTechnicalVisit(id) {
  try {
    let item = yield call(api.technicalVisit.one, id);

    if (item) {
      const {
        technicalVisitsCached: {
          [item.technicalVisit.id]: cachedTechnicalVisit,
        },
        technicalVisitsSyncablePaths: {
          [item.technicalVisit.id]: technicalVisitSyncingPaths,
        },
      } = yield select(appDataSelector);
      const haveToBeMerged =
        !!cachedTechnicalVisit && !!technicalVisitSyncingPaths;

      // if (haveToBeMerged) yield put(actions.setSyncStatus('caching'));

      item.images = yield call(fetchImagesCollections, item);

      if (haveToBeMerged) {
        // const syncingPaths = JSON.parse(
        //   JSON.stringify(technicalVisitSyncingPaths),
        // );
        item = yield mergeTechnicalVisits(
          item,
          cachedTechnicalVisit,
          technicalVisitSyncingPaths,
          id,
        );

        // yield put(appActions.updateSyncablePaths({ id, data: syncingPaths }));
      }

      yield put(appActions.addOrUpdateCachedTv(item));
    }
  } catch (e: any) {
    console.log(e);
    if (e.message === '404') actions.removeTvFromCached(id);
    throw e;
  }
}

function* mergeTechnicalVisits(
  { images: fetchedCollections, ...fetched },
  { images: cachedCollections, ...cached },
  syncingPaths,
  technicalVisitId,
) {
  const images = yield mergeImagesCollections(
    fetchedCollections,
    cachedCollections,
    syncingPaths,
    technicalVisitId,
  );

  const mergedData = {};
  for (const resourceKey of Object.keys(cached)) {
    const mergedResource = yield mergeResources(
      fetched[resourceKey],
      cached[resourceKey],
      syncingPaths,
      resourceKey,
      technicalVisitId,
    );

    if (mergedResource) mergedData[resourceKey] = mergedResource;
  }

  return {
    images,
    ...fetched,
    ...mergedData,
  };
}

function* mergeResources(
  fetched,
  cached,
  syncingPaths,
  syncingPath,
  technicalVisitId,
) {
  if (_.isArray(cached)) {
    const mergedItems = fetched.reduce(
      (acc, fetchedItem) => ({
        ...acc,
        [(fetchedItem as any).id]: fetchedItem,
      }),
      {},
    );

    for (let cachedItem of cached) {
      const fetchedItem = mergedItems[cachedItem.id];
      const mergedItem = yield mergeItem(
        fetchedItem,
        cachedItem,
        syncingPaths,
        [syncingPath, cachedItem.id].join('.'),
        technicalVisitId,
      );

      if (mergedItem) mergedItems[cachedItem.id] = mergedItem;
    }

    return _.compact(
      Object.keys(mergedItems).map((itemId) => mergedItems[itemId]),
    );
  }
  return yield mergeItem(
    fetched,
    cached,
    syncingPaths,
    syncingPath,
    technicalVisitId,
  );
}

function* mergeImagesCollections(
  fetchedCollections,
  cachedCollections,
  syncingPaths,
  technicalVisitId,
) {
  const mergedCollections = {};
  for (const collectionName of Object.keys(cachedCollections)) {
    mergedCollections[collectionName] = yield mergeImages(
      fetchedCollections[collectionName],
      cachedCollections[collectionName],
      syncingPaths,
      `images.${collectionName}`,
      technicalVisitId,
    );
  }

  return {
    ...fetchedCollections,
    ...mergedCollections,
  };
}

function* mergeImages(
  fetchedImages = [],
  cachedImages,
  syncingPaths,
  syncingPathPrefix,
  technicalVisitId,
) {
  const mergedImages = fetchedImages.reduce(
    (acc, fetchedImage) => ({
      ...acc,
      [(fetchedImage as any).id]: fetchedImage,
    }),
    {},
  );

  for (let cachedImage of cachedImages) {
    const fetchedImage = mergedImages[cachedImage.id];
    const mergedImage = yield mergeItem(
      fetchedImage,
      cachedImage,
      syncingPaths,
      [syncingPathPrefix, cachedImage.id].join('.'),
      technicalVisitId,
    );

    if (mergedImage) mergedImages[cachedImage.id] = mergedImage;
  }

  return _.compact(
    Object.keys(mergedImages).map((imageId) => mergedImages[imageId]),
  );
}

function* mergeItem(
  fetched,
  cached,
  syncingPaths,
  syncingPath,
  technicalVisitId,
) {
  const syncingParams = syncingPaths[syncingPath]?.[0];

  if (fetched && syncingParams) {
    const fetchedIsNewer =
      Math.max(
        new Date(fetched.updatedAt).getTime(),
        new Date(cached?.updatedAt || null).getTime(),
      ) > new Date(cached?.updatedAt || null).getTime();

    if (syncingParams.action === 'delete') {
      yield call(localforage.removeItem, cached.id);
    } else if (syncingParams.action === 'create' || fetchedIsNewer) {
      yield put(
        actions.removeSyncablePath({
          id: technicalVisitId,
          path: syncingPath,
          pathItemId: syncingParams.id,
        }),
      );
      yield call(localforage.removeItem, cached.id);
      return fetched;
    } else {
      return cached;
    }
  } else if (syncingParams && syncingParams.action === 'create') {
    return cached;
  } else {
    console.log(technicalVisitId, syncingPath, syncingParams);
    if (syncingParams)
      yield put(
        actions.removeSyncablePath({
          id: technicalVisitId,
          path: syncingPath,
          pathItemId: syncingParams.id,
        }),
      );
    yield call(localforage.removeItem, cached.id);
  }
}

// const mergedImages = yield all(
//   cachedImages.map(function* (cachedImage) {
//     const fetchedImage = _.find(
//       fetchedImages,
//       ({ id }) => id === cachedImage.id,
//     );

//     if (fetchedImage) {
//       const keepCached =
//         Math.max(
//           new Date((fetchedImage as any).updatedAt).getTime(),
//           new Date(cachedImage.updatedAt || null).getTime(),
//         ) === new Date(cachedImage.updatedAt || null).getTime();

//       console.log(keepCached);
//       if (!keepCached) {
//         yield call(localforage.removeItem, cachedImage.id);
//         _.unset(
//           syncingKeys,
//           [syncingPathPrefix, (fetchedImage as any).id].join('.'),
//         );
//         // yield put(
//         //   actions.removeSyncablePath({
//         //     id: technicalVisitId,
//         //     path: [syncingPathPrefix, (fetchedImage as any).id].join('.'),
//         //   }),
//         // );
//       }
//       return keepCached ? cachedImage : fetchedImage;
//     } else if (!!syncingKeys[[syncingPathPrefix, cachedImage.id].join('.')]) return cachedImage;
//   }),
// );

// return _.unionBy(_.compact([...mergedImages, ...fetchedImages]), 'id');
