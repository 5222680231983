import { UserInterface, userInitialState, States } from 'types';
import { TechnicalVisitPageState } from './containers/TechnicalVisitPage/types';
export enum Resources {
  TECHNICAL_VISIT = 'technicalVisit',
  CUSTOMER = 'customer',
  ROOF_FRAME = 'roofFrame',
  ELECTRICITY = 'electricity',
  GENERATOR = 'generator',
  RADIATOR = 'radiator',
  EQUIPMENT_HANDLES = 'equipmentHandles',
  DOCUMENT_DT = 'documentDt',
  SELF_CONSUMPTION = 'selfConsumption',
}

export enum formResources {
  roofFrame = 'RoofFrameForms',
  electricities = 'ElectricityForms',
  electricity = 'ElectricityForms',
  'technical-visits' = 'TechnicalVisitForms',
  'technicalVisit' = 'TechnicalVisitForms',
  'roof-frames' = 'RoofFrameForms',
  generators = 'GeneratorForms',
  generator = 'GeneratorForms',
  'document-dts' = 'DocumentDtForms',
  'documentDt' = 'DocumentDtForms',
  radiators = 'RadiatorForms',
  radiator = 'RadiatorForms',
  customers = 'CustomerForms',
  'equipment-handles' = 'EquipmentHandlesForms',
  equipmentHandles = 'EquipmentHandlesForms',
  'self-consumptions' = 'SelfConsumptionForms',
  selfConsumption = 'SelfConsumptionForms',
}
export enum routeResources {
  'electricity' = 'electricities',
  'technicalVisit' = 'technical-visits',
  'roofFrame' = 'roof-frames',
  'generator' = 'generators',
  'documentDt' = 'document-dts',
  'radiator' = 'radiators',
  'customer' = 'customers',
  'equipmentHandles' = 'equipment-handles',
  'selfConsumption' = 'self-consumptions',
}

export type SyncablePathActions = 'delete' | 'create' | 'update';
export type SynchronizationStatus =
  | 'awaiting'
  | 'caching'
  | 'syncing'
  | 'paused'
  | 'resuming';
export interface SyncablePathParams {
  id: string;
  action: SyncablePathActions;
  data: any;
  extra?: any;
}

export interface Config {
  version: string;
}

/* --- TYPES AND INTERFACES --- */
export interface AppDataState {
  appIsReady: boolean;
  currentUser: UserInterface;
  apiCalls: string[];
  isCallingApi: boolean;
  isSuperadmin: boolean;
  isAdmin: boolean;
  isBasic: boolean;
  isSupplier: boolean;
  isManager: boolean;
  onSmallDevice: boolean;
  darkMode: boolean;
  lists: ListInterface[];
  disableNotifications: boolean;
  userDialogParams: UserDialogParams;
  resourceDialogParams: ResourceDialogParams;
  technicalVisitsSyncablePaths: {
    [vtId: string]: {
      [path: string]: SyncablePathParams[];
    };
  };
  syncStatus: {
    current: SynchronizationStatus;
    previous?: SynchronizationStatus;
  };
  syncFailureCounter: number;
  technicalVisit: ResultsInterface;
  myTechnicalVisits: TechnicalVisitDataInterface[];
  technicalVisitsCached: { [key: string]: TechnicalVisitPageState };
  lastMergingAt?: number;
  config?: Config;
}

export interface CustomerInterface {
  id?: string;
  firstname: string;
  lastname: string;
  address?: string;
  city?: string;
  zip?: number;
  coordinates?: string;
  phone?: string;
  mobile: string;
  mail?: string;
  society?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface TechnicalVisitInterface {
  id?: string;
  technician: string;
  technicianName?: string;
  timestamp?: Date;
  internetProvider: string;
  selling: string;
  typeOfSelling?: number;
  appointmentAt: Date;
  houseArea: string;
  cadastralPlot: string;
  status: States;
  createdAt?: string;
  updatedAt?: string;
}
export interface TechnicalVisitDataInterface {
  customer: CustomerInterface;
  technicalVisit: TechnicalVisitInterface;
}
export interface ResultsInterface {
  items: TechnicalVisitDataInterface[];
  total: number;
  lastFetchedPage: number;
  fetchingOrFetchedPages: number[];
  totalPages: number;
}
export interface SetItemsParams {
  resource: string;
  items: ResultsInterface[];
}

export interface SetFiltersParams {
  filterName: string;
  filterData: any;
}

export interface ListInterface {
  id?: number;
  parentId?: number;
  key: string;
  name: string;
  sublists: SubListInterface[];
}

export interface SubListInterface {
  id: number;
  key: string;
  name: string;
  hasSublist: boolean;
  isLoading?: boolean;
}

export interface UserDialogParams {
  user: UserInterface;
  isOpen: boolean;
  asProfile: boolean;
}

/* --- INITIAL STATES --- */
export const initialUserDialogParams: UserDialogParams = {
  user: userInitialState,
  isOpen: false,
  asProfile: false,
};

export interface AddOrUpdateListParams {
  id?: number;
  key: string;
  name?: string;
  parentId?: number;
}
export interface exportCachedData {
  download?: boolean;
}

export const appDataInitialState: AppDataState = {
  appIsReady: false,
  currentUser: userInitialState,
  userDialogParams: initialUserDialogParams,
  apiCalls: [],
  isCallingApi: false,
  isSuperadmin: false,
  isAdmin: false,
  isBasic: false,
  isSupplier: false,
  isManager: false,
  onSmallDevice: false,
  darkMode: false,
  disableNotifications: false,
  lists: [],
  resourceDialogParams: {},
  technicalVisitsSyncablePaths: {},
  syncStatus: {
    current: 'awaiting',
  },
  syncFailureCounter: 0,
  technicalVisit: {
    items: [],
    total: 0,
    lastFetchedPage: 0,
    fetchingOrFetchedPages: [],
    totalPages: 0,
  },
  myTechnicalVisits: [],
  technicalVisitsCached: {},
};
export interface StepForm {
  resource: Resources;
  stepLabel: string;
  refresh: boolean;
}

export interface ResourceDialogParams {
  title?: string;
  resources?: StepForm[];
  refreshResources?: [Resources];
  items?: any;
  foreignKey?: any;
  readOnly?: boolean;
  isDuplicate?: boolean;
}

export interface SetItemParams {
  resource: string;
  item: any;
  foreignKey?: any;
  isDuplicate?: boolean;
}

export interface AddOrUpdateItemParams<T> {
  resource: Resources;
  item: Partial<T>;
  refreshResource?: boolean;
  isDuplicate?: boolean;
  key?: string;
}

export interface SearchTechnicalVisitsFilters {
  technician?: number[];
  appointmentAt: Date[] | null[];
  name?: string;
  city?: string;
  zip?: number;
  status?: string[];
}

export interface SearchTechnicalVisitsBody {
  page: number;
  sortBy: string;
  sortDirection: string;
  filter: SearchTechnicalVisitsFilters;
}

export interface SearchTechnicalVisitsParams {
  fetchNextPage: boolean;
  body: SearchTechnicalVisitsBody;
  query?: { [key: string]: string | number };
}

export interface FetchTechnicalVisitsParams {
  technician?: string;
  appointmentAt?: Date[] | null[];
  status?: States;
  id?: string | string[];
}
