import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { translations } from 'locales/i18n';
import { appDataSelector } from 'app/selectors';
import { useStepperContext } from '../Context';

import { Box, TextField, Typography } from '@material-ui/core';
import { FormikInput } from 'app/components/FormikInput';
import { MenuOption, SimpleSelect } from 'app/components/SimpleSelect';
import { ElectricityInterface } from '../types';
import { useFormikContext } from 'formik';

export const Form = () => {
  const { setFieldValue } = useFormikContext<ElectricityInterface>();
  const { t } = useTranslation();
  const { setValidationSchema, variant } = useStepperContext();
  const { onSmallDevice, lists, isSupplier } = useSelector(appDataSelector);
  const [listsOptions, setListsOptions] = React.useState(
    {} as { [key: string]: MenuOption[] },
  );

  const [refresh, setRefresh] = React.useState(false);
  const [listKeys] = React.useState([
    'power',
    'envoy',
    'ac_protection',
    'self_consumption_type',
  ]);

  React.useEffect(() => {
    if (refresh) setRefresh(false);
  }, [refresh]);

  React.useEffect(() => {
    setRefresh(true);
    const options = {};
    // eslint-disable-next-line array-callback-return
    listKeys.map((key) => {
      const currentList = lists.find((l) => l.key === key)?.sublists;
      options[key] = currentList?.map((cl) => ({
        value: cl.id,
        displayedValue: cl.name,
      })) as MenuOption[];
    });
    setListsOptions(options);
  }, [setListsOptions, lists, listKeys]);

  React.useEffect(
    () =>
      setValidationSchema(
        yup.object({
          selfConsumptionType: yup.number().nullable(),
          powerAlreadyInstalled: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          existingEnvoy: yup.number().nullable(),
          boxFieldSection: yup
            .string()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          inverterMark: yup.string().nullable(),
          envoyNumber: yup.string().nullable(),
          panelNbr: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          typeFieldSection: yup.number().nullable(),
          connectionRequestNumber: yup.string().nullable(),
          installationYear: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          comment: yup.string().nullable(),
        }),
      ),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      {!refresh && (
        <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
          <Box>
            <Typography variant="h4" gutterBottom>
              Auto consommation
            </Typography>
            <>
              <Box
                display="flex"
                flexDirection={onSmallDevice ? 'column' : 'row'}
              >
                <FormikInput
                  name="selfConsumptionType"
                  label={t(
                    translations.SelfConsumptionForms.labels
                      .selfConsumptionType,
                  )}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    options: listsOptions['self_consumption_type'] || [],
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '2' }}
                  component={SimpleSelect}
                />
                <FormikInput
                  name="inverterMark"
                  label={t(
                    translations.SelfConsumptionForms.labels.inverterMark,
                  )}
                  extraAttr={{
                    ...variant,
                    style: { width: '100%' },
                    disabled: isSupplier,
                  }}
                  containerStyle={{ flex: '2' }}
                  component={TextField}
                />
              </Box>
              <Box
                display="flex"
                flexDirection={onSmallDevice ? 'column' : 'row'}
              >
                <FormikInput
                  name="panelNbr"
                  label={t(translations.SelfConsumptionForms.labels.panelNbr)}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '1' }}
                  component={TextField}
                />
                <FormikInput
                  name="powerAlreadyInstalled"
                  label={t(
                    translations.SelfConsumptionForms.labels
                      .powerAlreadyInstalled,
                  )}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    onChange: (e) =>
                      setFieldValue(
                        'powerAlreadyInstalled',
                        e.target.value
                          ? e.target.value.replaceAll(',', '.')
                          : null,
                      ),
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '2' }}
                  component={TextField}
                />
              </Box>
              <Box
                display="flex"
                flexDirection={onSmallDevice ? 'column' : 'row'}
              >
                <FormikInput
                  name="existingEnvoy"
                  label={t(
                    translations.SelfConsumptionForms.labels.existingEnvoy,
                  )}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    options: listsOptions['envoy'] || [],
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '2' }}
                  component={SimpleSelect}
                />
                <FormikInput
                  name="envoyNumber"
                  label={t(
                    translations.SelfConsumptionForms.labels.envoyNumber,
                  )}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '1' }}
                  component={TextField}
                />
              </Box>
              <Box
                display="flex"
                flexDirection={onSmallDevice ? 'column' : 'row'}
              >
                <FormikInput
                  name="installationYear"
                  label={t(
                    translations.SelfConsumptionForms.labels.installationYear,
                  )}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '1' }}
                  component={TextField}
                />
                <FormikInput
                  name="connectionRequestNumber"
                  label={t(
                    translations.SelfConsumptionForms.labels
                      .connectionRequestNumber,
                  )}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '1' }}
                  component={TextField}
                />
              </Box>
              <Box
                display="flex"
                flexDirection={onSmallDevice ? 'column' : 'row'}
              >
                <FormikInput
                  name="boxFieldSection"
                  label={t(
                    translations.SelfConsumptionForms.labels.boxFieldSection,
                  )}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '3' }}
                  component={TextField}
                />

                <FormikInput
                  name="typeFieldSection"
                  label={t(
                    translations.SelfConsumptionForms.labels.typeFieldSection,
                  )}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    options: listsOptions['power'] || [],
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '2' }}
                  component={SimpleSelect}
                />
              </Box>
              <Box
                display="flex"
                flexDirection={onSmallDevice ? 'column' : 'row'}
                marginBottom="1em"
              >
                <FormikInput
                  name="acProtection"
                  label={t(
                    translations.SelfConsumptionForms.labels.acProtection,
                  )}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    options: listsOptions['ac_protection'] || [],
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '2' }}
                  component={SimpleSelect}
                />
              </Box>
              <Box
                display="flex"
                flex={6}
                flexDirection={onSmallDevice ? 'column' : 'row'}
              >
                <FormikInput
                  name="comment"
                  label={t(translations.SelfConsumptionForms.labels.comment)}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    rows: 6,
                    multiline: true,
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '6' }}
                  component={TextField}
                />
              </Box>
            </>
          </Box>
        </Box>
      )}
    </>
  );
};
