import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { translations } from 'locales/i18n';
import { appDataSelector } from 'app/selectors';
import { Resources as SettingsRessources } from 'app/containers/SettingsPage/types';
import { dashboardPageSelector } from '../selectors';
import { actions } from '../slice';
import { actions as appActions } from 'app/slice';
import { NetworkContext } from 'providers/NetworkProvider';

import { Resources } from '../types';

import {
  TechnicalVisitInterface,
  CustomerInterface,
  TechnicalVisitDataInterface,
} from 'app/types';
import {
  customer as customerInitialState,
  technicalVisit as technicalVisitInitialState,
} from '../initialStates';

import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import styled from 'styled-components/macro';

import FilterListIcon from '@material-ui/icons/FilterList';
import {
  VirtualizedTable,
  SortDirection,
  RowActions,
} from 'app/components/VirtualizedTable';
import { MenuOption } from 'app/components/SimpleSelect';
import { ConfirmDialog } from 'app/components/ConfirmDialog';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import {
  BASIC,
  CANCELED,
  PENDING,
  PLANIFIED,
  REALIZED,
  REFUSED,
  States,
  TECHNICAL_INFEASIBILITY,
  TO_RESCHEDULE,
  TO_REVIEW,
  VALIDATED,
} from 'types';
import { MultipleSelect } from 'app/components/CustomSelect/MultipleSelect';
import { DateTimeRangePicker } from 'app/components/DateTimeRangePicker';
import {
  CheckCircle,
  CheckCircleOutline,
  HighlightOff,
  NotInterested,
  RadioButtonUncheckedOutlined,
  RemoveCircleOutlineOutlined,
  Search,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import DialogHistory from './components/dialogHistory';
import { TechnicalVisitPageState } from 'app/containers/TechnicalVisitPage/types';
import { SearchTechnicalVisitsBody } from 'app/types';

enum SelectOptionsKeys {
  USERS = 'users',
}

const selectOptions = Object.values(SelectOptionsKeys).reduce(
  (r, k) => ({
    ...r,
    [k]: [],
  }),
  {},
) as { [k in SelectOptionsKeys]: MenuOption[] };

interface Props {
  refreshAt: Date;
}

export const TechnicalVisit = ({ refreshAt }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { offline, online } = React.useContext(NetworkContext);

  const isNetworkOnline = React.useMemo(() => !offline && online, [
    offline,
    online,
  ]);
  const [
    confirmStatusDialogProps,
    setConfirmStatusDialogProps,
  ] = React.useState({
    isOpen: false,
    action: RowActions.DELETE,
    technicalVisit: {} as Partial<TechnicalVisitInterface>,
  });
  const [dialogHistory, setDialogHistory] = React.useState({
    isOpen: false,
    id: '',
  });

  const [isHovered, setIsHovered] = React.useState<null | number>(null);

  const {
    users,
    filters: { technicalVisits: technicalVisitFilters },
  } = useSelector(dashboardPageSelector);

  const {
    onSmallDevice,
    isAdmin,
    isSuperadmin,
    technicalVisitsCached,
    lists,

    currentUser,
    technicalVisit: {
      items: technicalVisit,
      fetchingOrFetchedPages,
      totalPages,
      total: totalTechnicalVisits,
    },
  } = useSelector(appDataSelector);

  const [scrollData, setScrollData] = React.useState({
    clientHeight: 0,
    scrollHeight: 0,
    scrollTop: 0,
  });
  const [onFiltering, setFilteringState] = React.useState(false);
  const [options, setOptions] = React.useState(selectOptions);
  const [queryParams, setQueryParams] = React.useState(technicalVisitFilters);
  const [customerFilter, setLocalFilters] = React.useState<{
    zip?: number;
    city?: string;
    name?: string;
  }>({
    zip: technicalVisitFilters?.filters?.zip || undefined,
    city: technicalVisitFilters?.filters?.city || undefined,
    name: technicalVisitFilters?.filters?.name || undefined,
  });
  const debouncedScrollData = useDebounce(scrollData, 100);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const currentFilters = React.useMemo(
    () => ({ ...customerFilter, ...technicalVisitFilters.filter }),
    [customerFilter, technicalVisitFilters.filter],
  );

  const setCustomerFilter = (data) => {
    setLocalFilters(data);
    dispatch(
      actions.setFilters({
        filterName: 'technicalVisits',
        filterData: { filter: data },
      }),
    );
  };

  const { technicalVisits, total } = React.useMemo(() => {
    const technicalVisits = isNetworkOnline
      ? technicalVisit
      : _.orderBy(
          Object.values(technicalVisitsCached).filter(
            (item) => item.technicalVisit.technician === currentUser.id,
          ),
          (item) => item.technicalVisit.appointmentAt,
        );
    return {
      technicalVisits,
      total: isNetworkOnline ? totalTechnicalVisits : technicalVisits.length,
    };
  }, [
    technicalVisit,
    totalTechnicalVisits,
    technicalVisitsCached,
    isNetworkOnline,
    currentUser.id,
  ]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const searchTechnicalVisits = React.useCallback(
    (query = undefined) => {
      const { fetchNextPage, ...body } = queryParams;

      dispatch(
        appActions.searchTechnicalVisits({
          fetchNextPage,
          body: {
            ...body,
            filter: {
              ...body?.filter,
              appointmentAt: body?.filter?.appointmentAt || [null, null],
            },
          },
          query,
        }),
      );

      dispatch(
        actions.setFilters({
          filterName: 'technicalVisits',
          filterData: queryParams,
        }),
      );
      setFilteringState(false);
    },
    [dispatch, queryParams],
  );

  const onChangeStatus = (
    { id }: TechnicalVisitInterface,
    action: RowActions,
  ) => {
    if (
      [RowActions.DELETE, RowActions.CANCEL, RowActions.RESCHEDULE].includes(
        action,
      )
    ) {
      setConfirmStatusDialogProps({
        isOpen: true,
        action,
        technicalVisit: { id },
      });
    } else {
      changeStatus({ id }, action);
    }
  };

  const closeConfirmStatusDialog = () =>
    setConfirmStatusDialogProps({
      ...confirmStatusDialogProps,
      isOpen: false,
      technicalVisit: {},
    });

  const onConfirmChangeStatus = () => {
    changeStatus(
      { id: confirmStatusDialogProps.technicalVisit.id },
      confirmStatusDialogProps.action,
    );
    closeConfirmStatusDialog();
  };

  const setOptionsWhenFetched = React.useCallback(
    <T extends { id?: number | string }>(
      key: SelectOptionsKeys,
      items: T[],
      displayedKey: keyof T,
    ) => {
      setOptions((currentState) => ({
        ...currentState,
        [key]: (items.map(({ id, [displayedKey]: displayedValue }) => ({
          value: id,
          displayedValue,
        })) as unknown) as MenuOption[],
      }));
    },
    [],
  );

  const changeStatus = (item, action) => {
    if (action === RowActions.CANCEL)
      dispatch(
        appActions.addOrUpdateItem({
          resource: Resources.TECHNICAL_VISIT,
          refreshResource: true,
          item: {
            id: item.id,
            status: CANCELED,
          },
        }),
      );
    if (action === RowActions.RESCHEDULE) {
      dispatch(
        appActions.addOrUpdateItem({
          resource: Resources.TECHNICAL_VISIT,
          refreshResource: true,
          item: {
            id: item.id,
            appointmentAt: null,
            status: TO_RESCHEDULE,
          },
        }),
      );
    }
    if (action === RowActions.REQUEST_VALIDATION) {
      dispatch(
        appActions.addOrUpdateItem({
          resource: Resources.TECHNICAL_VISIT,
          refreshResource: true,
          item: {
            id: item.id,
            status: PENDING,
          },
        }),
      );
    }
    if (action === RowActions.DELETE) {
      dispatch(
        actions.deleteItem({
          resource: Resources.TECHNICAL_VISIT,
          id: item.id,
        }),
      );
    }
  };

  React.useEffect(() => {
    if (totalPages === 0) searchTechnicalVisits();
  }, [totalPages, technicalVisit]);

  const setFilters = React.useCallback(
    (params: Partial<SearchTechnicalVisitsBody>) => {
      setQueryParams((currentState) => ({
        ...currentState,
        ...params,
        page: 1,
      }));
    },
    [],
  );

  React.useEffect(() => {
    dispatch(
      actions.fetchFilters({
        resources: [{ name: SettingsRessources.USERS }],
      }),
    );
  }, [dispatch]);

  React.useEffect(() => {
    setOptionsWhenFetched(
      SelectOptionsKeys.USERS,
      users.filter((u) => u.role === BASIC),
      'name',
    );
  }, [setOptionsWhenFetched, users]);

  const onScroll = React.useCallback(() => {
    const isPageFetchable = (p: number) =>
      !fetchingOrFetchedPages.includes(p) && p <= totalPages;
    const { scrollHeight, scrollTop } = debouncedScrollData;
    const elemSize = scrollHeight / totalTechnicalVisits;
    const displayedRow = Math.floor(scrollTop / elemSize);
    const curPage = Math.ceil(displayedRow / 100);
    const pageRow = displayedRow - (curPage - 1) * 100;
    const isCurPageFetchable = isPageFetchable(curPage);
    const isNextPageFetchable = pageRow > 40 && isPageFetchable(curPage + 1);

    if (isCurPageFetchable || isNextPageFetchable) {
      setQueryParams((currentState) => ({
        ...currentState,
        page: isCurPageFetchable ? curPage : curPage + 1,
        fetchNextPage: isCurPageFetchable && isNextPageFetchable,
      }));
    }
  }, [
    debouncedScrollData,
    fetchingOrFetchedPages,
    totalTechnicalVisits,
    totalPages,
  ]);

  const debouncedSetFilters = useMemo(() => _.debounce(setFilters, 1000), [
    setFilters,
  ]);

  React.useEffect(() => {
    if (queryParams.page !== 0) searchTechnicalVisits();
  }, [searchTechnicalVisits, queryParams]);

  React.useEffect(() => {
    if (queryParams.page === 0) {
      setFilters({});
    }
  }, [setFilters]);

  React.useEffect(() => {
    onScroll();
  }, [onScroll, debouncedScrollData]);

  const setTimestamp = (index: 0 | 1, date: Date | null = null) => {
    let timestamp = [...queryParams.filter.appointmentAt];
    timestamp[index] = date;
    setFilters({
      filter: {
        ...currentFilters.filter,
        appointmentAt: timestamp,
      },
    });
  };

  const setDialogParams = (
    technicalVisit: TechnicalVisitInterface,
    customer?: CustomerInterface,
  ) => {
    const resources = [
      {
        stepLabel: 'Création du client',
        resource: Resources.CUSTOMER,
        refresh: false,
      },
      {
        stepLabel: 'Informations complémentaires',
        resource: Resources.TECHNICAL_VISIT,
        refresh: true,
      },
    ];
    if (!customer) {
      resources.shift();
    }
    dispatch(
      appActions.setResourceDialogParams({
        title: t(translations.TechnicalVisitForms.detailsTitle),
        items: {
          [Resources.CUSTOMER]: customer,
          [Resources.TECHNICAL_VISIT]: technicalVisit,
        },
        resources,
      }),
    );
  };

  const colorStatus = {
    [REFUSED]: '#e46464b3',
    [CANCELED]: '#e46464b3',
    [VALIDATED]: '#6eb26e',
    [PENDING]: 'rgb(216 242 216)',
    [TO_RESCHEDULE]: 'rgb(255 187 138)',
    [REALIZED]: 'rgb(110 173 232 / 68%)',
  };

  const rowStyle = ({
    technicalVisit,
  }: TechnicalVisitDataInterface | TechnicalVisitPageState) =>
    colorStatus[technicalVisit?.status]
      ? {
          background: colorStatus[technicalVisit?.status],
        }
      : {};

  const icons = [
    {
      type: RowActions.EDIT,
      label: t(translations.TableRowActions.showEdit),
      handler: ({ rowData }) =>
        isAdmin || isSuperadmin
          ? setDialogParams(rowData?.technicalVisit)
          : history.push(`/technical-visit/${rowData.id}`),
    },
  ];
  const adminIcons = [
    RowActions.CANCEL,
    RowActions.RESCHEDULE,
    RowActions.REQUEST_VALIDATION,
  ];

  const superadminIcons = [
    RowActions.REQUEST_VALIDATION,
    RowActions.RESCHEDULE,
    RowActions.DELETE,
  ];

  React.useEffect(() => {
    setFilters({});
  }, [refreshAt]);

  const setCanView = (canView, id) => {
    if (isSuperadmin || isAdmin) {
      dispatch(
        appActions.addOrUpdateItem({
          resource: Resources.TECHNICAL_VISIT,
          refreshResource: true,
          item: {
            id,
            canView,
          },
        }),
      );
    }
  };

  return (
    <>
      <Box display="flex" padding="1em">
        {(isAdmin || isSuperadmin) && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              setDialogParams(
                technicalVisitInitialState.technicalVisit,
                customerInitialState,
              )
            }
          >
            {t(translations.TechnicalVisitTable.createTV)}
          </Button>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent={isAdmin || isSuperadmin ? 'flex-end' : 'flex-start'}
        padding="10px 1em 0 1em"
      >
        <Typography style={{ color: '#afaba6' }}>
          Total : {totalTechnicalVisits}
        </Typography>
      </Box>
      <Box
        display="flex"
        width={onSmallDevice ? '-webkit-fill-available' : '100%'}
        overflow="auto hidden"
        flexGrow="1"
      >
        <VirtualizedTable
          onScroll={(data) => setScrollData(data)}
          rowCount={total}
          headerHeight={96}
          rowGetter={({ index }) => {
            const row = technicalVisits[index];

            return row?.technicalVisit?.id &&
              technicalVisitsCached[row.technicalVisit.id]
              ? technicalVisitsCached[row.technicalVisit.id]
              : row;
          }}
          rowStyle={({ index }) =>
            technicalVisits[index] && rowStyle(technicalVisits[index])
          }
          initialSortParams={{
            sortBy: 'appointmentAt',
            sortDirection: SortDirection.DESC,
          }}
          onRowClick={({ rowData: { customer, ...rowData } }) => {
            history.push(`/technical-visit/${rowData.technicalVisit.id}`);
          }}
          hasHorizontalScroll={true}
          scrollTop={onFiltering}
          sort={(sort) => {
            if (
              sort.sortDirection !== queryParams.sortDirection ||
              sort.sortBy !== queryParams.sortBy
            ) {
              setFilters({
                sortDirection:
                  queryParams.sortDirection === 'ASC' ? 'DESC' : 'ASC',
                sortBy: sort.sortBy,
              });
            }
          }}
          columns={[
            {
              isVisible: isSuperadmin || isAdmin,
              width: 120,
              label: 'Accès fournisseur',
              dataKey: 'technicalVisit.canView',
              cellRenderer: ({ cellData, rowData, rowIndex }) => (
                <Checkbox
                  onMouseEnter={() => setIsHovered(rowIndex)}
                  onMouseLeave={() => setIsHovered(null)}
                  style={{ paddingLeft: 0 }}
                  checked={cellData}
                  icon={
                    <Tooltip title="Non visible">
                      {isHovered === rowIndex ? (
                        <CheckCircleOutline color="secondary" />
                      ) : (
                        <RadioButtonUncheckedOutlined />
                      )}
                    </Tooltip>
                  }
                  checkedIcon={
                    <Tooltip title="Visible">
                      {isHovered === rowIndex ? (
                        <HighlightOff style={{ color: 'grey' }} />
                      ) : (
                        <CheckCircle />
                      )}
                    </Tooltip>
                  }
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    setCanView(e.target.checked, rowData.technicalVisit.id)
                  }
                />
              ),
            },
            {
              width: 360,
              isSortable: isNetworkOnline,
              label: isNetworkOnline ? (
                <DateTimeRangePicker
                  label={t(translations.TechnicalVisitTable.date)}
                  fromPickerProps={{
                    key: 'from',
                    label: t(translations.TechnicalVisitTable.from),
                    value: queryParams.filter?.appointmentAt[0],
                    onChange: (date) => {
                      setTimestamp(0, date);
                      console.log(queryParams.filter);
                    },
                  }}
                  untilPickerProps={{
                    key: 'until',
                    label: t(translations.TechnicalVisitTable.until),
                    value: queryParams.filter?.appointmentAt[1],
                    onChange: (date) => {
                      console.log(date);
                      setTimestamp(1, date);
                      console.log(queryParams.filter);
                    },
                  }}
                />
              ) : (
                t(translations.TechnicalVisitTable.date)
              ),
              dataKey: 'technicalVisit.appointmentAt',
              cellRenderer: ({ cellData }) =>
                cellData && format(new Date(cellData), 'dd/MM/yy, HH:mm'),
            },
            {
              width: 190,
              label: isNetworkOnline ? (
                <MultipleSelect
                  label={t(translations.TechnicalVisitTable.technician)}
                  options={options.users}
                  style={{ width: '100%' }}
                  name="technician"
                  value={queryParams.filter.technician ?? []}
                  variant={'outlined'}
                  onChange={(value) => {
                    debouncedSetFilters({
                      filter: {
                        ...queryParams.filter,
                        technician: value as any,
                      },
                    });
                  }}
                />
              ) : (
                t(translations.TechnicalVisitTable.technician)
              ),
              dataKey: 'technicalVisit.technicianName',
            },
            {
              width: 200,
              label: isNetworkOnline ? (
                <TextField
                  label={t(translations.TechnicalVisitTable.customerName)}
                  name="name"
                  value={currentFilters?.name || ''}
                  variant="standard"
                  onChange={(event) => {
                    setCustomerFilter({
                      ...currentFilters,
                      name: event.target.value as any,
                    });
                    debouncedSetFilters({
                      filter: {
                        ...queryParams.filter,
                        name: event.target.value as any,
                      },
                    });
                  }}
                  InputProps={{
                    endAdornment: currentFilters?.name ? (
                      <InputAdornment
                        position="start"
                        onClick={(event) => {
                          setCustomerFilter({
                            ...currentFilters,
                            name: undefined,
                          });
                          debouncedSetFilters({
                            filter: {
                              ...queryParams.filter,
                              name: undefined,
                            },
                          });
                        }}
                      >
                        <IconButton color="secondary">
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                t(translations.TechnicalVisitTable.customerName)
              ),
              dataKey: 'customer.lastname',
              cellRenderer: ({ rowData }) =>
                `${rowData?.customer?.lastname || ''} ${
                  rowData?.customer?.firstname || ''
                }`,
            },
            {
              width: 440,
              label: isNetworkOnline ? (
                <>
                  {t(translations.TechnicalVisitTable.adress)}
                  <ShiftedIconButton
                    onClick={handleClick}
                    size="small"
                    style={{
                      color:
                        currentFilters.zip || currentFilters.city
                          ? 'rgb(186, 100, 0)'
                          : 'black',
                    }}
                  >
                    <FilterListIcon />
                  </ShiftedIconButton>
                  {(currentFilters.zip || currentFilters.city) && (
                    <ShiftedIconButton
                      onClick={() => {
                        setCustomerFilter({
                          zip: undefined,
                          city: undefined,
                        });
                        debouncedSetFilters({
                          filter: {
                            ...queryParams.filter,
                            zip: undefined,
                            city: undefined,
                          },
                        });
                      }}
                      size="small"
                      style={{
                        color: 'red',
                      }}
                    >
                      <ClearIcon />
                    </ShiftedIconButton>
                  )}
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      boxShadow={2}
                      style={{ background: 'white' }}
                      padding={2}
                    >
                      <TextField
                        label={t(translations.TechnicalVisitTable.zip)}
                        name="zip"
                        value={currentFilters?.zip || ''}
                        variant="standard"
                        onChange={(event) => {
                          setCustomerFilter({
                            ...currentFilters,
                            zip: event.target.value
                              ? parseInt(event.target.value as any)
                              : undefined,
                          });
                          debouncedSetFilters({
                            filter: {
                              ...queryParams.filter,
                              zip: parseInt(event.target.value as any),
                            },
                          });
                        }}
                        InputProps={{
                          endAdornment: currentFilters?.zip && (
                            <InputAdornment
                              position="start"
                              onClick={() => {
                                setCustomerFilter({
                                  ...currentFilters,
                                  zip: undefined,
                                });
                                debouncedSetFilters({
                                  filter: {
                                    ...queryParams.filter,
                                    zip: undefined,
                                  },
                                });
                              }}
                            >
                              <IconButton color="secondary">
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label={t(translations.TechnicalVisitTable.city)}
                        name="city"
                        value={currentFilters?.city || ''}
                        variant="standard"
                        onChange={(event) => {
                          setCustomerFilter({
                            ...currentFilters,
                            city: event.target.value as any,
                          });
                          debouncedSetFilters({
                            filter: {
                              ...queryParams.filter,
                              city: event.target.value as any,
                            },
                          });
                        }}
                        InputProps={{
                          endAdornment: currentFilters?.city && (
                            <InputAdornment
                              position="start"
                              onClick={() => {
                                setCustomerFilter({
                                  ...currentFilters,
                                  city: undefined,
                                });
                                debouncedSetFilters({
                                  filter: {
                                    ...queryParams.filter,
                                    city: undefined,
                                  },
                                });
                              }}
                            >
                              <IconButton color="secondary">
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Popover>
                </>
              ) : (
                t(translations.TechnicalVisitTable.adress)
              ),
              dataKey: 'customer.address',
              cellRenderer: ({ rowData }) =>
                [
                  rowData?.customer?.address,
                  [rowData?.customer?.zip, rowData?.customer?.city]
                    .filter((v) => v && `${v}`.length > 0)
                    .join(' '),
                ]
                  .filter((v) => v && v.length > 0)
                  .join(' - '),
            },
            {
              width: 200,
              isSortable: isNetworkOnline,
              label: isNetworkOnline ? (
                <MultipleSelect
                  label={t(translations.TechnicalVisitTable.status)}
                  options={
                    Object.values(States).map((v) => ({
                      value: v,
                      displayedValue: t(translations.StatusesIcons[v]),
                    })) as MenuOption[]
                  }
                  style={{ width: '100%' }}
                  name="status"
                  value={queryParams.filter.status ?? []}
                  variant={'outlined'}
                  onChange={(value) => {
                    setFilters({
                      filter: {
                        ...queryParams.filter,
                        status: value as any,
                      },
                    });
                  }}
                />
              ) : (
                t(translations.TechnicalVisitTable.status)
              ),
              dataKey: 'technicalVisit.status',
              cellRenderer: ({ cellData }) =>
                t(translations.StatusesIcons[cellData]),
            },
            {
              isSortable: isNetworkOnline,
              width: 250,
              label: isNetworkOnline ? (
                <MultipleSelect
                  label={t(translations.TechnicalVisitTable.selling)}
                  options={
                    lists
                      ?.find((list) => list.key === 'type_of_selling')
                      ?.sublists.map((sublist) => ({
                        value: sublist.id,
                        displayedValue: sublist.name,
                      })) as MenuOption[]
                  }
                  style={{ width: '100%' }}
                  name="typeOfSelling"
                  value={queryParams.filter.typeOfSelling ?? []}
                  variant={'outlined'}
                  onChange={(value) => {
                    debouncedSetFilters({
                      filter: {
                        ...queryParams.filter,
                        typeOfSelling: value as any,
                      },
                    });
                  }}
                />
              ) : (
                t(translations.TechnicalVisitTable.selling)
              ),
              dataKey: `technicalVisit.selling`,
              cellRenderer: ({ rowData, cellData }) =>
                `${cellData} kWc (${
                  lists?.find(
                    (list) =>
                      list.id === rowData?.technicalVisit?.typeOfSelling,
                  )?.name
                })`,
            },
          ]}
          actionsParams={{
            icons: [
              ...icons,
              ...(isSuperadmin
                ? [
                    {
                      type: RowActions.HISTORY,
                      label: t(translations.TableRowActions.showHistory),
                      handler: ({ rowData }) => {
                        setDialogHistory({
                          isOpen: true,
                          id: rowData?.technicalVisit.id,
                        });
                      },
                    },
                  ]
                : []),
              ...(isAdmin || isSuperadmin
                ? adminIcons.map((type) => ({
                    type: type,
                    label: t(translations.TableRowActions[type]),
                    handler: ({ rowData }) =>
                      onChangeStatus(rowData?.technicalVisit, type),
                    isVisible: (
                      technicalVisitData: TechnicalVisitDataInterface,
                    ) =>
                      [
                        ...([RowActions.CANCEL].includes(type)
                          ? [
                              PENDING,
                              TO_RESCHEDULE,
                              PLANIFIED,
                              REALIZED,
                              TO_REVIEW,
                            ]
                          : []),
                        ...([RowActions.RESCHEDULE].includes(type)
                          ? [PENDING, PLANIFIED, VALIDATED, REFUSED, REALIZED]
                          : []),
                        ...([RowActions.REQUEST_VALIDATION].includes(type)
                          ? [TECHNICAL_INFEASIBILITY]
                          : []),
                      ].includes(technicalVisitData?.technicalVisit?.status),
                  }))
                : []),

              ...(isSuperadmin
                ? superadminIcons.map((type) => ({
                    type: type,
                    label: t(translations.TableRowActions[type]),
                    handler: ({ rowData }) =>
                      onChangeStatus(rowData?.technicalVisit, type),
                    isVisible: (
                      technicalVisitData: TechnicalVisitDataInterface,
                    ) =>
                      [
                        ...([RowActions.REQUEST_VALIDATION].includes(type)
                          ? [CANCELED]
                          : []),

                        ...([RowActions.RESCHEDULE].includes(type)
                          ? [CANCELED]
                          : []),
                        ...([RowActions.DELETE].includes(type)
                          ? Object.values(States)
                          : []),
                      ].includes(technicalVisitData?.technicalVisit?.status),
                  }))
                : []),
            ],
            maxVisibleIcons: 5,
          }}
        />
      </Box>

      <ConfirmDialog
        title={t(translations.TableRowActions[confirmStatusDialogProps.action])}
        content={t(
          translations.ConfirmDialogActionsContent[
            confirmStatusDialogProps.action
          ],
          {
            item: t(
              translations.ConfirmDialogActionsContentItem.technicalVisit,
            ),
          },
        )}
        isOpen={confirmStatusDialogProps.isOpen}
        cancelLabel={t(translations.ConfirmDialog.cancel)}
        confirmLabel={t(translations.ConfirmDialog.confirm)}
        onClose={() => closeConfirmStatusDialog()}
        onConfirm={onConfirmChangeStatus}
      />
      <DialogHistory
        open={dialogHistory.isOpen}
        id={dialogHistory.id}
        handleClose={() =>
          setDialogHistory({
            isOpen: false,
            id: '',
          })
        }
      />
    </>
  );
};

function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

const ShiftedIconButton = styled(IconButton)`
  margin-left: 10px !important;
`;
