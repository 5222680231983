import _, { findIndex } from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  UserInterface,
  BASIC,
  ADMIN,
  SUPERADMIN,
  ADMIN_AVIGNON,
  ADMIN_LYON,
  BASIC_MANAGER,
  SUPPLIER,
} from 'types';
import {
  UserDialogParams,
  appDataInitialState as initialState,
  initialUserDialogParams,
  ListInterface,
  AddOrUpdateListParams,
  ResourceDialogParams,
  Resources,
  SetItemParams,
  AddOrUpdateItemParams,
  SyncablePathActions,
  SearchTechnicalVisitsParams,
  FetchTechnicalVisitsParams,
  ResultsInterface,
  TechnicalVisitDataInterface,
  SyncablePathParams,
  SynchronizationStatus,
  exportCachedData,
  Config,
} from './types';
import { TechnicalVisitPageState } from './containers/TechnicalVisitPage/types';

const removeSyncablePathFunc = (
  state,
  { id, path, pathItemId }: { id: string; path: string; pathItemId: string },
) => {
  const indexToDelete = state.technicalVisitsSyncablePaths[id]?.[
    path
  ]?.findIndex((pathItem) => pathItem.id === pathItemId);
  state.technicalVisitsSyncablePaths[id]?.[path]?.splice(indexToDelete, 1);

  if (state.technicalVisitsSyncablePaths[id]?.[path].length === 0)
    delete state.technicalVisitsSyncablePaths[id]?.[path];

  if (
    state.technicalVisitsSyncablePaths[id] &&
    Object.keys(state.technicalVisitsSyncablePaths[id]).length === 0
  )
    delete state.technicalVisitsSyncablePaths[id];
};

const userDialogSlice = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    fetchConfig() {},
    setConfig(state, action: PayloadAction<Config>) {
      state.config = action.payload;
    },
    initializeApp(state, action: PayloadAction<string>) {},
    showApp(state) {
      state.appIsReady = true;
    },
    setCurrentUser(state, action: PayloadAction<UserInterface>) {
      state.currentUser = action.payload;
      state.isSuperadmin = action.payload.role === SUPERADMIN;
      state.isAdmin = [ADMIN, ADMIN_AVIGNON, ADMIN_LYON].includes(
        action.payload.role,
      );
      state.isBasic = [BASIC, BASIC_MANAGER, SUPPLIER].includes(
        action.payload.role,
      );
      state.isSupplier = [SUPPLIER].includes(action.payload.role);
      state.isManager = action.payload.role === BASIC_MANAGER;
    },
    setApiCalls(state, action: PayloadAction<string>) {
      state.apiCalls.push(action.payload);
      state.isCallingApi = true;
    },
    unsetApiCalls(state, action: PayloadAction<string>) {
      _.remove(state.apiCalls, (apiCall) => apiCall === action.payload);

      state.isCallingApi = state.apiCalls.length > 0;
    },
    toggleDeviceFormat(state, action: PayloadAction<boolean>) {
      state.onSmallDevice = action.payload;
    },
    setUserDialogParams(state, action: PayloadAction<UserDialogParams>) {
      state.userDialogParams = action.payload;
    },
    resetUserDialogParams(state) {
      state.userDialogParams = initialUserDialogParams;
    },
    fetchLists(state, action: PayloadAction<Array<string>>) {},

    updateListTranslation(
      state,
      action: PayloadAction<{ id: number; name: string }>,
    ) {},
    fetchEntireLists(state, action: PayloadAction<undefined>) {},
    exportedCachedData(
      state,
      action: PayloadAction<exportCachedData | undefined>,
    ) {},
    setLists(state, action: PayloadAction<ListInterface[]>) {
      state.lists = action.payload;
    },
    updateList(state, action: PayloadAction<AddOrUpdateListParams>) {},
    setResourceDialogParams(
      state,
      action: PayloadAction<ResourceDialogParams>,
    ) {
      state.resourceDialogParams = action.payload;
    },
    resetResourceDialogParams(
      state,
      action: PayloadAction<[Resources] | undefined>,
    ) {
      state.resourceDialogParams = {};
      action?.payload?.forEach((resource) => (state[resource] = []));
    },
    setItemResourceDialogParams(state, action: PayloadAction<SetItemParams>) {
      const {
        resource,
        item,
        foreignKey,
        isDuplicate = false,
      } = action.payload;
      state.resourceDialogParams!.items![resource] = item as any;
      state.resourceDialogParams.foreignKey = foreignKey;
      state.resourceDialogParams.isDuplicate = isDuplicate;
    },
    addOrUpdateItem<T>(
      state,
      action: PayloadAction<AddOrUpdateItemParams<T>>,
    ) {},
    addSyncablePath(
      state,
      {
        payload: { id, path, params, method },
      }: PayloadAction<{
        id: string;
        path: string;
        method: 'push' | 'replace';
        params: SyncablePathParams;
      }>,
    ) {
      const pathIndex = method === 'push' ? 1 : 0;
      console.log(pathIndex);
      const currentAction =
        state.technicalVisitsSyncablePaths[id]?.[path]?.[pathIndex]?.action;
      const currentId =
        state.technicalVisitsSyncablePaths[id]?.[path]?.[pathIndex]?.id;

      if (
        currentAction &&
        currentAction === 'create' &&
        params.action === 'delete'
      ) {
        removeSyncablePathFunc(state, { id, path, pathItemId: currentId });
      } else if (
        !currentAction ||
        (currentAction === 'update' && params.action === 'delete')
      ) {
        state.technicalVisitsSyncablePaths[id]?.[path]
          ? (state.technicalVisitsSyncablePaths[id][path][pathIndex] = params)
          : (state.technicalVisitsSyncablePaths[id] = {
              ...(state.technicalVisitsSyncablePaths[id] || {}),
              [path]: [params],
            });
      } else if (state.technicalVisitsSyncablePaths[id]?.[path]?.[pathIndex]) {
        state.technicalVisitsSyncablePaths[id][path][pathIndex] = {
          ...state.technicalVisitsSyncablePaths[id][path][pathIndex],
          data: params.data,
          extra: params.extra,
        };
      }
    },
    removeSyncablePath(
      state,
      {
        payload,
      }: PayloadAction<{ id: string; path: string; pathItemId: string }>,
    ) {
      removeSyncablePathFunc(state, payload);
    },
    updateSyncablePaths(
      state,
      { payload: { id, data } }: PayloadAction<{ id: string; data: any }>,
    ) {
      if (Object.keys(data).length > 0)
        state.technicalVisitsSyncablePaths[id] = data;
      else delete state.technicalVisitsSyncablePaths[id];
    },
    searchTechnicalVisits(
      state,
      action: PayloadAction<SearchTechnicalVisitsParams>,
    ) {},
    startCaching() {},
    setTechnicalVisits(state, action: PayloadAction<ResultsInterface>) {
      state.technicalVisit = action.payload;
    },
    setMyTechnicalVisits(
      state,
      action: PayloadAction<TechnicalVisitDataInterface[]>,
    ) {
      state.myTechnicalVisits = action.payload;
    },
    removeTvFromCached(state, action: PayloadAction<string>) {
      delete state.technicalVisitsCached[action.payload];
      delete state.technicalVisitsSyncablePaths[action.payload];
    },
    addOrUpdateCachedTv(state, action: PayloadAction<TechnicalVisitPageState>) {
      const itemsNumberToDelete =
        Object.keys(state.technicalVisitsCached).length - 30;

      if (itemsNumberToDelete > 0) {
        let deletedItemsNumber = 0;
        Object.keys(state.technicalVisitsCached).forEach((vtId) => {
          if (
            deletedItemsNumber < itemsNumberToDelete &&
            (!state.technicalVisitsSyncablePaths[vtId] ||
              Object.keys(state.technicalVisitsSyncablePaths[vtId]).length ===
                0)
          ) {
            delete state.technicalVisitsCached[vtId];
            deletedItemsNumber++;
          }
        });
      }

      if (action.payload?.technicalVisit?.id) {
        // if (!!state.technicalVisitsCached[action.payload.technicalVisit.id]) {
        //   const defaultDate = '01-01-2000';
        //   const {
        //     technicalVisit: {
        //       updatedAt: currentUpdatedAt = defaultDate,
        //       resourcesUpdatedAt: currentResourcesUpdatedAt = defaultDate,
        //     },
        //   } = state.technicalVisitsCached[action.payload.technicalVisit.id];
        //   const {
        //     technicalVisit: {
        //       updatedAt = defaultDate,
        //       resourcesUpdatedAt = defaultDate,
        //     },
        //   } = action.payload;

        //   if (
        //     Math.max(
        //       new Date(updatedAt).getTime(),
        //       new Date(resourcesUpdatedAt).getTime(),
        //     ) <=
        //     Math.max(
        //       new Date(currentUpdatedAt).getTime(),
        //       new Date(currentResourcesUpdatedAt).getTime(),
        //     )
        //   )
        //     return;
        // }
        state.technicalVisitsCached[action.payload.technicalVisit.id] =
          action.payload;
      }
    },
    updateCachedTvItem(
      state,
      { payload: { path, item } }: PayloadAction<{ path: string; item: any }>,
    ) {
      if (_.get(state.technicalVisitsCached, path))
        _.set(state.technicalVisitsCached, path, item);
    },
    setCachedImages(
      state,
      {
        payload: { collectionName, technicalVisitId, images },
      }: PayloadAction<{
        collectionName: string;
        technicalVisitId: string;
        images;
      }>,
    ) {
      state.technicalVisitsCached[technicalVisitId].images[
        collectionName
      ] = images;
    },
    unsetCachedImages(
      state,
      {
        payload: { collectionName, technicalVisitId },
      }: PayloadAction<{
        collectionName: string;
        technicalVisitId: string;
      }>,
    ) {
      delete state.technicalVisitsCached[technicalVisitId].images[
        collectionName
      ];
    },
    addDraftResourceInCachedTechnicalVisit(
      state,
      {
        payload: { technicalVisitId, resource, values },
      }: PayloadAction<{
        technicalVisitId: string;
        resource: Resources;
        values: any;
      }>,
    ) {
      (state.technicalVisitsCached[technicalVisitId][
        resource
      ] as Array<any>).push(values);
    },
    removeDraftResourceFromCachedTechnicalVisit(
      state,
      {
        payload: { technicalVisitId, resource, index },
      }: PayloadAction<{
        technicalVisitId: string;
        resource: Resources;
        index: number;
      }>,
    ) {
      (state.technicalVisitsCached[technicalVisitId][
        resource
      ] as Array<any>).splice(index, 1);
    },
    startSyncing() {},
    setSyncStatus(state, action: PayloadAction<SynchronizationStatus>) {
      if (state.syncStatus.current !== action.payload) {
        state.syncStatus.previous = state.syncStatus.current;
        state.syncStatus.current = action.payload;
      }
    },
    incrementSyncFailureCounter(state) {
      state.syncFailureCounter++;
    },
    resetSyncFailureCounter(state) {
      state.syncFailureCounter = 0;
    },
    setLastMergingAt(state) {
      state.lastMergingAt = new Date().getTime();
    },
    resetLastMergingAt(state) {
      delete state.lastMergingAt;
    },
  },
});

export const { actions, reducer, name: sliceKey } = userDialogSlice;
