/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';

import { translations } from 'locales/i18n';
import { reducer, sliceKey, actions } from './slice';
import { setConfig } from 'utils/api';
import { appDataSelector } from './selectors';
import { actions as vtActions } from './containers/TechnicalVisitPage/slice';
import { appDataSaga } from 'store/sagas/appData';
import { lightTheme, darkTheme } from 'utils/theme';
import { NetworkContext } from 'providers/NetworkProvider';

import {
  Box,
  LinearProgress,
  withWidth,
  ThemeProvider,
} from '@material-ui/core';
import { UserDialog } from './containers/UserDialog';
import { CircularProgress } from './components/CircularProgress';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { NavBar } from './containers/NavBar';
import { SettingsPage } from './containers/SettingsPage';
import { DashboardPage } from './containers/DashboardPage';
import { TechnicalVisitPage } from './containers/TechnicalVisitPage';
import { UpdateDialog } from './containers/CreateOrUpdateItemDialog';
import { SynchronizationStateDetails } from './components/SynchronizationStatus';

interface SnackbarParams {
  status?: 'error' | 'success';
  message?: string;
}

interface Props {
  width: Breakpoint;
}

const App = withWidth()(({ width }: Props) => {
  // useInjectReducer({ key: sliceKey, reducer: reducer });
  // useInjectSaga({ key: sliceKey, saga: appDataSaga });

  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    logout,
  } = useAuth0();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [snackbarParams, setSnackbarParams] = React.useState(
    {} as SnackbarParams,
  );
  const {
    currentUser,
    isCallingApi,
    onSmallDevice,
    darkMode,
    userDialogParams,
    appIsReady,
    resourceDialogParams,
    syncStatus,
    technicalVisitsSyncablePaths,
    technicalVisitsCached,
  } = useSelector(appDataSelector);
  const location = useLocation();
  const setUserDialogParams = () => {
    dispatch(
      actions.setUserDialogParams({
        user: currentUser,
        isOpen: true,
        asProfile: true,
      }),
    );
  };
  const { offline, online } = React.useContext(NetworkContext);

  const isNetworkOnline = React.useMemo(() => !offline && online, [
    offline,
    online,
  ]);

  React.useEffect(() => {
    const manageSessionToken = async () => {
      const sessionToken = await getAccessTokenSilently({
        //TODO: ignoreCache only if application is online, else use cache
        ignoreCache: isNetworkOnline,
      }).catch((e) => {
        console.error(e);
        logout();
      });
      if (sessionToken) {
        setConfig(
          sessionToken,
          (url) => dispatch(actions.setApiCalls(url)),
          (url, status, showMessage) => {
            dispatch(actions.unsetApiCalls(url));
            setSnackbarParams({
              status,
              ...(showMessage
                ? {
                    message: t(translations.Snackbar[status]),
                  }
                : {}),
            });
          },
        );
        dispatch(actions.initializeApp(sessionToken));
      }
    };

    if (isAuthenticated && !isLoading) manageSessionToken();
  }, [
    dispatch,
    getAccessTokenSilently,
    logout,
    t,
    isAuthenticated,
    isLoading,
    isNetworkOnline,
  ]);

  React.useEffect(() => {
    dispatch(
      actions.toggleDeviceFormat(
        width === 'xs' || width === 'sm' || width === 'md',
      ),
    );
  }, [dispatch, width]);

  React.useEffect(() => {
    if (
      currentUser.lang &&
      _.indexOf(i18n.languages, currentUser.lang) &&
      currentUser.lang !== i18n.language
    )
      i18n.changeLanguage(currentUser.lang);
    document.querySelector('html')?.setAttribute('lang', currentUser.lang);
  }, [currentUser, i18n]);

  React.useEffect(() => {
    if (!location.pathname.includes('technical-visit/')) {
      dispatch(vtActions.resetState());
    }
  }, [dispatch, location]);

  React.useEffect(() => {
    if (
      ['awaiting', 'resuming'].includes(syncStatus.current) &&
      isNetworkOnline &&
      appIsReady
    )
      dispatch(actions.startSyncing());
  }, [
    dispatch,
    appIsReady,
    syncStatus,
    isNetworkOnline,
    technicalVisitsSyncablePaths,
  ]);

  React.useEffect(() => {
    if (appIsReady && isNetworkOnline) dispatch(actions.resetLastMergingAt());
  }, [dispatch, appIsReady, isNetworkOnline]);

  const synchronizationStatusDetail = React.useMemo(() => {
    if (offline) return 'offline';
    return syncStatus.current === 'paused' ? syncStatus.current : 'syncing';
  }, [syncStatus, offline]);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Helmet defaultTitle="Visitek"></Helmet>

      {appIsReady ? (
        <Layout display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
          <UserDialog
            offline={!isNetworkOnline}
            onClose={() => dispatch(actions.resetUserDialogParams())}
            {...userDialogParams}
          />
          <UpdateDialog {...resourceDialogParams} />
          <NavBar
            onOpenProfileDialogClick={setUserDialogParams}
            order={onSmallDevice ? 2 : 1}
            offline={!isNetworkOnline}
          />

          {syncStatus.current !== 'awaiting' && (
            <SynchronizationStateDetails
              status={synchronizationStatusDetail}
              label={t(translations.SyncStatus[synchronizationStatusDetail])}
              onForceSync={() => dispatch(actions.setSyncStatus('resuming'))}
            />
          )}

          <ContentView
            display="flex"
            flexDirection="column"
            alignItems={'stretch'}
            flexGrow="1"
            order={onSmallDevice ? 1 : 2}
          >
            <Switch>
              <Route exact path="/" component={DashboardPage} />
              <Route path="/settings" component={SettingsPage} />
              <Route path="/technical-visit" component={TechnicalVisitPage} />
              <Redirect to="/" />
            </Switch>
          </ContentView>
        </Layout>
      ) : (
        <CircularProgress />
      )}
    </ThemeProvider>
  );
});

const Layout = styled(Box)`
  width: 100%;
  height: 100%;
`;

const ContentView = styled(Box)`
  overflow-y: auto;
  flex: 1;
  padding: 20px;
  height: 100%;
`;

export default withAuthenticationRequired(App, {
  onRedirecting: () => (
    <ThemeProvider theme={lightTheme} children={<CircularProgress />} />
  ),
  loginOptions: {
    options: {
      ui_locales: ['fr', 'en'],
    },
  },
});
