import { PLANIFIED } from 'types';
import { TechnicalVisitDataInterface, CustomerInterface } from 'app/types';

export const customer: CustomerInterface = {
  firstname: '',
  lastname: '',
  address: '',
  city: '',
  zip: undefined,
  phone: '',
  mobile: '',
  mail: '',
};
export const technicalVisit: TechnicalVisitDataInterface = {
  customer: customer,
  technicalVisit: {
    technician: '',
    internetProvider: '',
    houseArea: '',
    status: PLANIFIED,
    cadastralPlot: '',
    selling: '',
    appointmentAt: new Date(),
  },
};
