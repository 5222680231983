import React, { useMemo } from 'react';
import localforage from 'localforage';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';
import { appDataSelector } from 'app/selectors';
import { actions, initialState } from './slice';
import { actions as appActions } from 'app/slice';
import { routeResources } from 'app/types';
import Skeleton from '@material-ui/lab/Skeleton';

import { Box, StepButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Formik, Form } from 'formik';
import { StepperContext } from './Context';
import {
  ExportImagesParams,
  Resources,
  TechnicalVisitPageState,
} from './types';
import _ from 'lodash';
import { FloatingSubmitBtn } from 'app/components/FloatingSubmitBtn';
import {
  PENDING,
  PLANIFIED,
  VALIDATED,
  REALIZED,
  TECHNICAL_INFEASIBILITY,
  TO_REVIEW,
} from 'types';
import { Fab, Tooltip, withTheme } from '@material-ui/core';
import { AttachFile, Delete } from '@material-ui/icons';
import { ImagesCollection, Collection } from './ImagesCollection';
import { roofFrame as initialRoofFrame } from './initialStates';
import { radiator as initialRadiator } from './initialStates';
import { selfConsumption as initialSelfConsumption } from './initialStates';
import { CameraSelect } from './CameraSelect';
import DialogComment from './components/dialogComment';
import Alert from '@material-ui/lab/Alert';
import fr from 'date-fns/esm/locale/fr/index.js';
import { ObjectID } from 'bson';

localforage.config({ name: 'files' });

const checkRessources = [
  { resource: Resources.ROOF_FRAME, step: 1 },
  { resource: Resources.TECHNICAL_VISIT, step: 2 },
  { resource: Resources.ELECTRICITY, step: 3 },
  { resource: Resources.GENERATOR, step: 4 },
  { resource: Resources.EQUIPMENT_HANDLES, step: 5 },
];

const useStyles = makeStyles({
  content: {
    justifyContent: 'center',
  },
  maxWidth50: {
    maxWidth: '48%',
    margin: '1% 1%',
  },
  width100: {
    width: '100%',
  },
  redButton: {
    color: 'white',
    background: '#f44336',
  },
  greenButton: {
    background: '#4caf50',
    color: 'white',
  },
  backdrop: {
    zIndex: 9999,
    color: '#fff',
  },
  draftRessource: {
    border: '1px solid #ff000059',
  },
});

export function TechnicalVisitPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  // useInjectReducer({ key: sliceKey, reducer: reducer });
  // useInjectSaga({ key: sliceKey, saga: technicalVisitPageSaga });
  const { pathname } = useLocation();
  // const { images, history, ...items } = useSelector(
  //   technicalVisitPageSelector,
  // ) as any;
  const {
    isCallingApi,
    onSmallDevice,
    currentUser: { lang, ...currentUser },
    isAdmin,
    isSuperadmin,
    isSupplier,
    isBasic,
    technicalVisitsCached,
    technicalVisitsSyncablePaths,
  } = useSelector(appDataSelector);

  const [activeStep, setActiveStep] = React.useState(0);
  const [canChangeStep, setCanChangeStep] = React.useState(true);

  const [dialogComment, setDialogComment] = React.useState({
    isOpen: false,
    data: {},
  });

  const formRef = React.useRef<any>([]);

  const technicalVisitId = React.useMemo(() => {
    const id = pathname.split('/').slice(-1)[0];
    dispatch(actions.setTechnicalVisitId(id));
    return id;
  }, [dispatch, pathname]);

  const { images = {}, ...items }: TechnicalVisitPageState = React.useMemo(
    () =>
      !!technicalVisitsCached[technicalVisitId]
        ? technicalVisitsCached[technicalVisitId]
        : initialState,
    [technicalVisitsCached, technicalVisitId],
  );

  const resources: any = useMemo(
    () =>
      [
        {
          stepLabel: 'Client',
          resource: Resources.CUSTOMER,
          buttons: [],
        },
        {
          accordionTitle: t(translations.TechnicalVisitForms.buttons.roof),
          stepLabel: 'Toits',
          resource: Resources.ROOF_FRAME,
          isVisible: items.technicalVisit.typeOfSellingKey !== 'solo_dmi',
          files: [
            { label: 'Toiture', key: 'toiture', required: true },
            { label: 'Charpente', key: 'charpente', required: true },
            { label: 'Implantation', key: 'implantation', required: true },
            { label: 'Obstacles', key: 'obstacles' },
            { label: 'Autre', key: 'other' },
          ],
          buttons: [
            {
              label: t(translations.TechnicalVisitForms.buttons.addRoof),
              values: initialRoofFrame,
            },
          ],
        },
        {
          stepLabel: 'Visite technique',
          resource: Resources.TECHNICAL_VISIT,
          buttons: [],
          files: [
            {
              label: 'Entrée de la maison/portail',
              key: 'entree_maison',
              required: true,
            },
            {
              label: "Emplacement d'installation en façade",
              key: 'emplacement_facade',
              required: false,
            },
            { label: 'Rue (1)', key: 'rue_1', required: false },
            { label: 'Rue (2)', key: 'rue_2', required: false },
            { label: 'Rue (3)', key: 'rue_3', required: false },
            { label: 'Rue (4)', key: 'rue_4', required: false },
            {
              label: "Toit d'installation (depuis la rue)",
              key: 'toit_installation',
            },
            { label: 'Accès (si difficile)', key: 'acces' },
            { label: 'Tranchée (si necessaire)', key: 'tranchee' },
            { label: 'Autre', key: 'other' },
          ],
        },
        {
          stepLabel: 'Electricité',
          resource: Resources.ELECTRICITY,
          buttons: [],
          files: [
            {
              label: 'Tableau électrique de loin',
              key: 'tableau_elec_loin',
              required: true,
            },
            {
              label: 'Tableau électrique de près',
              key: 'tableau_elec_pres',
              required: true,
            },
            {
              label: 'Tableau électrique ouvert de près',
              key: 'tableau_elec_ouvert',
              required: true,
            },
            { label: 'Compteur', key: 'compteur', required: true },
            { label: 'Disjoncteur', key: 'disjoncteur', required: true },
            { label: 'N° PDL Linky', key: 'pdl_linky' },
            { label: 'Coffret extérieur', key: 'coffret_exterieur' },
            {
              label: 'Coffret extérieur de loin',
              key: 'coffret_exterieur_loin',
            },
            { label: 'Câble ENEDIS', key: 'cable_enedis' },
            { label: 'Poteau électrique', key: 'poteau_elec' },
            { label: 'Autre', key: 'other' },
          ],
        },
        {
          accordionTitle: t(
            translations.TechnicalVisitForms.buttons.selfConsumption,
          ),
          stepLabel: 'Auto-consommations',
          resource: Resources.SELF_CONSUMPTION,
          buttons: [
            {
              label: t(
                translations.TechnicalVisitForms.buttons.addSelfConsumption,
              ),
              values: initialSelfConsumption,
            },
          ],
          files: [{ label: 'Autre', key: 'other' }],
          isVisible: !!items?.electricity?.selfConsumption,
        },
        {
          stepLabel: 'Générateur',
          resource: Resources.GENERATOR,
          buttons: [],
          files: [
            {
              label: 'Emplacement DMI avec recul',
              key: 'emplacement_dmi',
              required: true,
            },
            { label: 'Autre', key: 'other' },
          ],
        },
        {
          stepLabel: 'Equipements',
          resource: Resources.EQUIPMENT_HANDLES,
          isVisible: items.technicalVisit.typeOfSellingKey !== 'solo_pg',
          buttons: [
            {
              label: t(translations.TechnicalVisitForms.buttons.addOutlet),
              values: { isOutlet: true },
            },
            {
              label: t(translations.TechnicalVisitForms.buttons.addEquipment),
              values: { isOutlet: false },
            },
          ],
          files: [
            {
              label: 'Equipement',
              key: 'equipement',
              required: false,
            },
            {
              label: 'Emplacement',
              key: 'emplacement',
              required: false,
            },
            { label: 'Autre', key: 'other' },
          ],
        },
        {
          stepLabel: 'Chauffages',
          resource: Resources.RADIATOR,
          isVisible: items.technicalVisit.typeOfSellingKey !== 'solo_pg',
          buttons: [
            {
              label: t(translations.TechnicalVisitForms.buttons.addRadiator),
              values: initialRadiator,
            },
          ],
          files: [
            {
              label: 'Radiateur (si tête thermostatique)',
              key: 'radiateur_thermostatique',
            },
            { label: 'Autre', key: 'other' },
          ],
        },
        {
          stepLabel: 'Documents',
          isVisible:
            (isAdmin || isSuperadmin || isSupplier) &&
            ![PLANIFIED].includes(items.technicalVisit.status),
          resource: Resources.DOCUMENT_DT,
          buttons: [],
          uploadIcon: AttachFile,
          acceptFile: 'application/pdf',
          files: [
            {
              label: 'Calepinage',
              key: 'dt_calepinage',
              required:
                items.technicalVisit.typeOfSellingKey === 'solo_pg' ||
                items.technicalVisit.typeOfSellingKey === 'pg_dmi',
            },
            {
              label: 'Photos avant/après',
              key: 'dt_before_after',
              required:
                items.technicalVisit.typeOfSellingKey === 'solo_pg' ||
                items.technicalVisit.typeOfSellingKey === 'pg_dmi',
            },
            {
              label: 'Emplacement DMI',
              key: 'dt_dmi',
              required:
                items.technicalVisit.typeOfSellingKey === 'solo_dmi' ||
                items.technicalVisit.typeOfSellingKey === 'pg_dmi',
            },
            {
              label: 'CALSOL',
              key: 'dt_calsol',
              required: false,
            },
            {
              label: 'SIGNATURE',
              key: 'dt_signature',
              required: true,
            },
            { label: 'Autre', key: 'other' },
          ],
        },
      ].filter(({ isVisible = true }) => isVisible),
    [t, items, isAdmin, isSuperadmin],
  );

  const hasImages = useMemo(() => images && Object.keys(images).length > 0, [
    images,
  ]);
  const [item, setItem] = React.useState(items[resources[activeStep].resource]);

  const documentsCollections: Collection[] = useMemo(() => {
    if (!images) return [];
    const path = `${technicalVisitId}/${
      item?.id && item.id !== technicalVisitId
        ? item?.id
        : resources[activeStep].resource
    }`;

    const { accordionTitle } = resources[activeStep];
    return (_.isArray(item)
      ? item.map(({ id, title }, index) => ({
          path: `${path}/${id}`,
          name: `${accordionTitle} ${title?.[lang] || index + 1}`,
        }))
      : [{ path }]
    ).filter(({ path }) => !!images[path] && images[path].length > 0);
  }, [item, activeStep, technicalVisitId, lang, images, resources]);

  const pushResource = (resource, values) => {
    dispatch(
      appActions.addDraftResourceInCachedTechnicalVisit({
        technicalVisitId,
        resource,
        values,
      }),
    );
  };

  const spliceResource = (resource, index) => {
    dispatch(
      appActions.removeDraftResourceFromCachedTechnicalVisit({
        technicalVisitId,
        resource,
        index,
      }),
    );
  };

  const handleNext = () => {
    if (currentUser.id === items.technicalVisit.technician) {
      saveForms();
    }
    setTimeout(() => {
      setItem(null);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, 40);
  };

  const handleBack = () => {
    saveForms();

    setTimeout(() => {
      setItem(null);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, 40);
  };

  const handleStep = (resource) => () => {
    saveForms();
    setTimeout(() => {
      setItem(null);
      let index = resources.findIndex((r) => r.resource === resource);
      setActiveStep(index);
    }, 40);
  };

  const saveForms = () => {
    for (var i in formRef.current) {
      formRef.current[i] &&
        formRef.current[i].dirty &&
        formRef.current[i].handleSubmit();
    }
  };
  const changeStatus = (change) => {
    dispatch(
      actions.addOrUpdateItem({
        resource: Resources.TECHNICAL_VISIT,
        item: {
          ...items.technicalVisit,
          ...change,
          baseUrl: `${window.location.origin}/technical-visit/`,
          customer: `${items.customer.lastname} ${items.customer.zip}`,
        },
      }),
    );
  };

  const checkMandatoryFiles = (path, currentFiles, mandatoryFiles) => {
    if (currentFiles?.length < mandatoryFiles?.length) return true;

    let mf = mandatoryFiles.map((m) => `${path}/${m.key}`);
    if (currentFiles?.length > 0) {
      mf.map((m) =>
        currentFiles.map((c) => {
          if (c.resources?.includes(m)) mf = mf.filter((f) => f !== m);
        }),
      );
    }
    return mf.length !== 0;
  };

  const addImage = async (
    file: File,
    name: string,
    collection: string,
    path: string,
    resource: { name: string; id: string },
    label,
  ) => {
    const id = new ObjectID().toString();

    // const fileReader = new FileReader();
    // const bufferedFile = await file.arrayBuffer();
    // console.log(bufferedFile);
    // fileReader.readAsArrayBuffer(file);

    // fileReader.onloadend = async (e) => {
    await localforage.setItem(id, file);
    let extension = file.type;
    if (extension.includes('jpeg') || extension.includes('jpg'))
      extension = 'jpeg';
    if (extension.includes('pdf')) extension = 'pdf';

    dispatch(
      actions.addOrUpdateImage({
        data: {
          id,
          extension,
          resourceName: resource.name,
          resourceId: resource.id,
          resourceNameTv: 'technical-visits',
          resourceIdTv: items.technicalVisit.id,
          title: label,
          path,
          name: `${name} ${new Date().toLocaleString()}`.replace(/, |'/g, ' '),
          type: file.type,
        },
        collection,
      }),
    );
    // };
  };

  const generatePdf = () => {
    dispatch(
      actions.generatePdf({
        id: items.technicalVisit.id,
        technicianId: items.technicalVisit.technician,
        documentDt: items.documentDt.id,
      }),
    );
  };

  const duplicateTechnicalVisit = () => {
    dispatch(
      appActions.setResourceDialogParams({
        title: t(translations.TechnicalVisitForms.duplicateTitle),
        items: {
          [Resources.TECHNICAL_VISIT]: items.technicalVisit,
        },
        isDuplicate: true,
        resources: [
          resources.find((r) => r.resource === Resources.TECHNICAL_VISIT),
        ],
      }),
    );
  };

  const removeItem = (id, resource, files, tags) => {
    dispatch(
      actions.removeItem({
        resource,
        id,
        tags,
      }),
    );
  };

  const handleClickOpenDialogComment = (data) => {
    setDialogComment({ isOpen: true, data });
  };

  const handleCloseDialogComment = (text) => {
    if (text) changeStatus({ ...dialogComment.data, comment: text });
    setDialogComment({ isOpen: false, data: {} });
  };

  const onImagesExport = () => {
    const folderStructure: ExportImagesParams['folderStructure'] = [];
    resources.forEach(({ resource, stepLabel, files }, idx) => {
      const collectionName = `${items.technicalVisit.id}/${
        items[resource]?.id && items[resource]?.id !== items.technicalVisit.id
          ? items[resource]?.id
          : resources[idx].resource
      }`;

      if (_.isArray(items[resource])) {
        items[resource].forEach(({ title, id, localisation }, index) => {
          const name = `${collectionName}/${id}`;
          const folderTitle =
            title?.[lang] || title || localisation || index + 1;
          if (images[name])
            folderStructure.push({
              path: `${stepLabel}/${folderTitle}`,
              files: images[name].map(({ id }) => id),
            });
        });
      } else if (images[collectionName]) {
        folderStructure.push({
          path: stepLabel,
          files: images[collectionName].map(({ id }) => id),
        });
      }
    });
    dispatch(
      actions.exportImages({
        name: `${items.customer.firstname} ${items.customer.lastname}`,
        folderStructure,
        id: technicalVisitId,
      }),
    );
  };

  const validationState = React.useMemo(() => {
    const stepsCollections = _.compact(
      resources.map(({ resource, files, accordionTitle, stepLabel }, step) => {
        if (!files) return files;
        const requiredFiles = files.filter(({ required }) => required);

        if (requiredFiles.length > 0) {
          const collectionName = `${items.technicalVisit.id}/${
            items[resource]?.id &&
            items[resource]?.id !== items.technicalVisit.id
              ? items[resource]?.id
              : resources[step].resource
          }`;

          const collection = _.compact(
            _.compact(
              _.isArray(items[resource])
                ? items[resource]
                    .filter(({ id }) => !!id)
                    .map(({ id }) => `${collectionName}/${id}`)
                : [items[resource]?.id ? collectionName : undefined],
            ).map((collection: any, index) => {
              const files = !!images[collection]
                ? _.compact(
                    requiredFiles.map(
                      (file) =>
                        images[collection].filter((image) =>
                          image?.resources?.includes(
                            `${collection}/${file.key}`,
                          ),
                        ).length === 0 && file,
                    ),
                  )
                : requiredFiles;

              return (
                files.length > 0 && {
                  title: accordionTitle
                    ? _.capitalize(accordionTitle) +
                        items[resource]?.[index]?.title?.[lang] ||
                      items[resource]?.[index]?.title ||
                      items[resource]?.[index]?.localisation ||
                      index + 1
                    : stepLabel,
                  files,
                }
              );
            }),
          );

          return collection.length > 0 && collection;
        }
      }),
    );
    return {
      validated: stepsCollections.length === 0,
      stepsCollections,
    };
  }, [images, items, resources, lang]);

  // const fetchImages = React.useCallback(() => {
  //   if (items.technicalVisit.id) {
  //     resources.map(({ resource, files }, idx) => {
  //       const collectionName = `${items.technicalVisit.id}/${
  //         items[resource]?.id && items[resource]?.id !== items.technicalVisit.id
  //           ? items[resource]?.id
  //           : resources[idx].resource
  //       }`;

  //       (_.isArray(items[resource])
  //         ? items[resource].map(({ id }) =>
  //             id ? `${collectionName}/${id}` : id,
  //           )
  //         : [collectionName]
  //       ).forEach((name) => {
  //         if (name && files && !images[name])
  //           dispatch(actions.fetchImages(name));
  //       });
  //     });
  //   }
  // }, [dispatch, items, resources, images]);

  React.useEffect(() => {
    setItem(items[resources[activeStep].resource]);
  }, [setItem, items, resources, activeStep]);

  React.useEffect(() => {
    if (technicalVisitId && !technicalVisitsSyncablePaths[technicalVisitId]) {
      dispatch(actions.fetchTechnicalVisit(technicalVisitId));
    }
  }, [dispatch, technicalVisitId]);
  // }, [dispatch, pathname, technicalVisitsCached]);

  // React.useEffect(() => {
  //   fetchImages();
  // }, [items.technicalVisit.id]);

  return (
    <>
      <StepperContext.Provider {...{ items }}>
        {items && resources && (
          <StepperContext.Consumer>
            {({ validationSchema, resourceForms }) => (
              <Box display="flex" flexDirection="row" height="100%">
                <Box
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  flex="1"
                >
                  <Stepper activeStep={activeStep} alternativeLabel nonLinear>
                    {resources.map(({ stepLabel, resource }, index) => (
                      <Step key={stepLabel}>
                        {!!technicalVisitsCached[technicalVisitId] && (
                          <StepButton
                            key={stepLabel}
                            onClick={handleStep(resource)}
                          >
                            <StepLabel>{stepLabel}</StepLabel>
                          </StepButton>
                        )}
                        {!technicalVisitsCached[technicalVisitId] && (
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            gridGap="10px"
                          >
                            <Skeleton variant="circle" width={24} height={24} />
                          </Box>
                        )}
                      </Step>
                    ))}
                  </Stepper>

                  {[TO_REVIEW, TECHNICAL_INFEASIBILITY].includes(
                    items.technicalVisit.status,
                  ) && (
                    <Box marginBottom={'1em'}>
                      <Alert severity="error">
                        {`${t(
                          translations.TechnicalVisitForms.alert.refuseText,
                        )} ${t(
                          translations.StatusesIcons[
                            items.technicalVisit.status
                          ],
                        )} : `}
                        {items.technicalVisit.comment}
                      </Alert>
                    </Box>
                  )}
                  {!!technicalVisitsCached[technicalVisitId] && (
                    <>
                      {Array.isArray(items[resources[activeStep].resource]) && (
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-start"
                          flex="1"
                          flexWrap="wrap"
                          alignItems="baseline"
                          alignContent="space-between"
                          marginBottom="2em"
                          overflow="auto"
                        >
                          {items[resources[activeStep].resource].map(
                            (i, index) => (
                              <Accordion
                                key={`${resources[activeStep].resource}_${index}`}
                                defaultExpanded={true}
                                classes={{
                                  rounded: onSmallDevice
                                    ? classes.width100
                                    : classes.maxWidth50,
                                  root: !i?.id ? classes.draftRessource : '',
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  classes={{
                                    content: classes.content,
                                  }}
                                >
                                  <Typography align="center">
                                    {
                                      resources.find(
                                        (r) =>
                                          r.resource ===
                                          resources[activeStep].resource,
                                      )?.accordionTitle
                                    }
                                    {i?.title?.[lang] ||
                                      i?.title ||
                                      i?.localisation ||
                                      index + 1}
                                    {!i?.id && (
                                      <Typography color="error" align="center">
                                        {t(
                                          translations.TechnicalVisitForms
                                            .labels.draft,
                                        )}
                                      </Typography>
                                    )}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  classes={{ root: classes.content }}
                                >
                                  <Formik
                                    innerRef={(el) =>
                                      (formRef.current[i.id] = el)
                                    }
                                    enableReinitialize
                                    initialValues={i}
                                    validationSchema={validationSchema}
                                    onSubmit={(values) => {
                                      dispatch(
                                        actions.addOrUpdateItem({
                                          resource:
                                            resources[activeStep].resource,
                                          item: {
                                            ..._.mapValues(values, (v) =>
                                              v === '' ? null : v,
                                            ),
                                            technicalVisitId:
                                              items.technicalVisit.id,
                                            tags: {
                                              'technical-visits':
                                                items.technicalVisit.id,
                                            },
                                          },
                                          index,
                                        }),
                                      );
                                    }}
                                  >
                                    {({ errors, dirty }) => {
                                      setCanChangeStep(_.isEmpty(errors));
                                      return (
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          flexDirection="column"
                                          component={Form}
                                        >
                                          <Box>
                                            {
                                              resourceForms[
                                                resources[activeStep].resource
                                              ]
                                            }
                                          </Box>
                                          {((isBasic &&
                                            (items.technicalVisit.status ===
                                              PLANIFIED ||
                                              items.technicalVisit.status ===
                                                REALIZED) &&
                                            items.technicalVisit.technician ===
                                              currentUser.id) ||
                                            isAdmin ||
                                            isSuperadmin) && (
                                            <Box
                                              display="flex"
                                              alignItems="center"
                                              justifyContent="center"
                                              flexDirection="row"
                                            >
                                              {!i.isOutlet && (
                                                <CameraSelect
                                                  disabled={!i.id}
                                                  collectionPath={`${items.technicalVisit.id}/${resources[activeStep].resource}/${i?.id}`}
                                                  files={
                                                    resources[activeStep].files
                                                  }
                                                  icon={
                                                    resources[activeStep]
                                                      ?.uploadIcon
                                                  }
                                                  uploaded={(key, path) =>
                                                    images[path]?.filter((f) =>
                                                      f.resources?.includes(
                                                        `${path}/${key}`,
                                                      ),
                                                    ).length > 0
                                                  }
                                                  onSelect={(file, params) =>
                                                    addImage(
                                                      file,
                                                      `${
                                                        params?.label ||
                                                        i?.roofFrameTitle?.[
                                                          lang
                                                        ] ||
                                                        i?.localisation ||
                                                        i?.name ||
                                                        resources[activeStep]
                                                          .resource
                                                      }`,
                                                      `${params?.path}`,
                                                      `${params?.path}/${params?.key}`,
                                                      {
                                                        name:
                                                          resources[activeStep]
                                                            .resource,
                                                        id: i.id,
                                                      },
                                                      `${
                                                        i?.title?.[lang] ||
                                                        i?.title ||
                                                        i?.localisation ||
                                                        index + 1
                                                      }`,
                                                    )
                                                  }
                                                />
                                              )}
                                              <RedFab
                                                onClick={() => {
                                                  if (i.id) {
                                                    const path = [
                                                      items.technicalVisit.id,
                                                      item?.id ||
                                                        resources[activeStep]
                                                          .resource,
                                                      i?.id,
                                                    ].join('/');
                                                    removeItem(
                                                      i.id,
                                                      resources[activeStep]
                                                        .resource,
                                                      images[
                                                        `${items.technicalVisit.id}/${resources[activeStep].resource}`
                                                      ]?.filter((f) =>
                                                        f.resources?.includes(
                                                          path,
                                                        ),
                                                      ),
                                                      {
                                                        'technical-visits':
                                                          items.technicalVisit
                                                            .id,
                                                      },
                                                    );
                                                  } else {
                                                    spliceResource(
                                                      resources[activeStep]
                                                        .resource,
                                                      index,
                                                    );
                                                  }
                                                }}
                                                type="button"
                                                color="primary"
                                              >
                                                <Delete />
                                              </RedFab>
                                              <FloatingSubmitBtn
                                                dataTestId="details-form-submit"
                                                disabled={
                                                  !dirty ||
                                                  // isCallingApi ||
                                                  (isBasic &&
                                                    currentUser.id !==
                                                      items.technicalVisit
                                                        .technician)
                                                }
                                              />
                                            </Box>
                                          )}
                                        </Box>
                                      );
                                    }}
                                  </Formik>
                                </AccordionDetails>
                              </Accordion>
                            ),
                          )}
                        </Box>
                      )}

                      {((isBasic &&
                        currentUser.id === items.technicalVisit.technician) ||
                        isAdmin ||
                        isSuperadmin) &&
                        Array.isArray(
                          items[resources[activeStep].resource],
                        ) && (
                          <Box
                            display="flex"
                            alignItems="center"
                            flexDirection="row"
                            gridColumnGap="1em"
                          >
                            {resources.find(
                              (r) =>
                                r.resource === resources[activeStep].resource,
                            )?.buttons &&
                              resources[activeStep]!.buttons.map(
                                ({ values, label }, idx) => (
                                  <Button
                                    key={label}
                                    disabled={
                                      items[resources[activeStep].resource]
                                        .length > 0 &&
                                      (!items[resources[activeStep].resource][
                                        items[resources[activeStep].resource]
                                          .length - 1
                                      ]?.id ||
                                        (!items[resources[activeStep].resource][
                                          items[resources[activeStep].resource]
                                            .length - 1
                                        ].isOutlet &&
                                          checkMandatoryFiles(
                                            [
                                              items.technicalVisit.id,
                                              resources[activeStep].resource,
                                              items[
                                                resources[activeStep].resource
                                              ][
                                                items[
                                                  resources[activeStep].resource
                                                ].length - 1
                                              ].id ||
                                                resources[activeStep].resource,
                                            ].join('/'),
                                            images[
                                              `${items.technicalVisit.id}/${
                                                resources[activeStep].resource
                                              }/${
                                                items[
                                                  resources[activeStep].resource
                                                ][
                                                  items[
                                                    resources[activeStep]
                                                      .resource
                                                  ].length - 1
                                                ].id ||
                                                resources[activeStep].resource
                                              }`
                                            ],
                                            resources[activeStep].files.filter(
                                              (f) => f.required,
                                            ),
                                          )))
                                    }
                                    onClick={() =>
                                      pushResource(
                                        resources[activeStep].resource,
                                        {
                                          ...values,
                                          // id: uuid(),
                                          // uuid: uuid(),
                                          // createdAt: new Date(),
                                          // updatedAt: new Date(),
                                        },
                                      )
                                    }
                                    variant="contained"
                                    color="primary"
                                  >
                                    {label}
                                  </Button>
                                ),
                              )}
                          </Box>
                        )}
                      {!Array.isArray(
                        items[resources[activeStep].resource],
                      ) && (
                        <Box
                          overflow="auto"
                          flex="1"
                          boxShadow={1}
                          padding="2em"
                        >
                          <Formik
                            innerRef={(el) => (formRef.current[item?.id] = el)}
                            enableReinitialize={true}
                            initialValues={item || {}}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                              dispatch(
                                actions.addOrUpdateItem({
                                  resource: resources[activeStep].resource,
                                  item: {
                                    ..._.mapValues(values, (v) =>
                                      v === '' ? null : v,
                                    ),
                                    technicalVisitId: !values.createAt
                                      ? items.technicalVisit.id
                                      : undefined,
                                    tags: {
                                      'technical-visits':
                                        items.technicalVisit.id,
                                    },
                                  },
                                }),
                              );
                            }}
                          >
                            {({ errors, dirty }) => {
                              setCanChangeStep(_.isEmpty(errors));
                              return (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="column"
                                  component={Form}
                                >
                                  <Box>
                                    {
                                      resourceForms[
                                        resources[activeStep].resource
                                      ]
                                    }
                                  </Box>

                                  {((isBasic &&
                                    (items.technicalVisit.status ===
                                      PLANIFIED ||
                                      items.technicalVisit.status ===
                                        REALIZED) &&
                                    items.technicalVisit.technician ===
                                      currentUser.id) ||
                                    isAdmin ||
                                    isSuperadmin) && (
                                    <Box
                                      display="flex "
                                      alignItems="center"
                                      flexDirection="row"
                                    >
                                      {activeStep !== 0 && (
                                        <CameraSelect
                                          disabled={item && !item.id}
                                          collectionPath={`${
                                            items.technicalVisit.id
                                          }/${
                                            [
                                              Resources.TECHNICAL_VISIT,
                                              Resources.CUSTOMER,
                                            ].includes(
                                              resources[activeStep].resource,
                                            )
                                              ? resources[activeStep].resource
                                              : item?.id
                                          }`}
                                          files={resources[activeStep].files}
                                          icon={
                                            resources[activeStep]?.uploadIcon
                                          }
                                          accept={
                                            resources[activeStep]?.acceptFile
                                          }
                                          uploaded={(key, path) =>
                                            images[path]?.filter((f) =>
                                              f.resources?.includes(
                                                `${path}/${key}`,
                                              ),
                                            ).length > 0
                                          }
                                          onSelect={(file, params) =>
                                            addImage(
                                              file,
                                              params?.label ||
                                                resources[activeStep].stepLabel,
                                              `${params?.path}`,
                                              `${params?.path}/${params?.key}`,
                                              {
                                                name:
                                                  resources[activeStep]
                                                    .resource,
                                                id: item.id,
                                              },
                                              ``,
                                            )
                                          }
                                        />
                                      )}
                                      <FloatingSubmitBtn
                                        dataTestId="details-form-submit"
                                        disabled={
                                          !dirty ||
                                          // isCallingApi ||
                                          (isBasic &&
                                            currentUser.id !==
                                              items.technicalVisit.technician)
                                        }
                                      />
                                    </Box>
                                  )}
                                </Box>
                              );
                            }}
                          </Formik>
                        </Box>
                      )}
                    </>
                  )}
                  {!technicalVisitsCached[technicalVisitId] && (
                    <Box display="block" margin="0 auto">
                      <Box display="flex" gridGap="1em" flexDirection="column">
                        <Box
                          display="flex"
                          gridGap="1em"
                          flexDirection={onSmallDevice ? 'column' : 'row'}
                        >
                          <Skeleton variant="rect" width={223} height={56} />
                          <Skeleton variant="rect" width={223} height={56} />
                        </Box>

                        <Box
                          display="flex"
                          gridGap="1em"
                          flexDirection={onSmallDevice ? 'column' : 'row'}
                        >
                          <Skeleton variant="rect" width="100%" height={56} />
                        </Box>
                        <Box
                          display="flex"
                          gridGap="1em"
                          flexDirection={onSmallDevice ? 'column' : 'row'}
                        >
                          <Skeleton variant="rect" width={223} height={56} />
                          <Skeleton variant="rect" width={223} height={56} />
                        </Box>

                        <Box display="flex" gridGap="1em">
                          <Skeleton variant="rect" width={223} height={56} />
                        </Box>
                        <Box
                          display="flex"
                          gridGap="1em"
                          justifyContent="center"
                        >
                          <Skeleton variant="circle" width={56} height={56} />
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {!!technicalVisitsCached[technicalVisitId] && (
                    <Box
                      display="flex"
                      alignItems={onSmallDevice ? 'stretch' : 'center'}
                      flexDirection="row"
                      justifyContent="space-between"
                      flexWrap="wrap"
                      margin="11px 0px"
                      gridGap="1em"
                    >
                      {!isSupplier && (
                        <Button
                          disabled={activeStep === 0 || !canChangeStep}
                          onClick={handleBack}
                          variant="outlined"
                          color="secondary"
                        >
                          {t(translations.TechnicalVisitForms.buttons.back)}
                        </Button>
                      )}
                      {!isBasic && hasImages && (
                        <Button
                          onClick={onImagesExport}
                          variant="outlined"
                          color="secondary"
                        >
                          Exporter les images
                        </Button>
                      )}
                      {items.technicalVisit.status === PENDING &&
                        (isAdmin || isSuperadmin) &&
                        activeStep === resources.length - 1 && (
                          <>
                            <Button
                              onClick={() =>
                                handleClickOpenDialogComment({
                                  status: TO_REVIEW,
                                  appointmentAt: null,
                                })
                              }
                              variant="contained"
                              classes={{
                                contained: classes.redButton,
                              }}
                            >
                              {t(
                                translations.TechnicalVisitForms.buttons
                                  .returnVisit,
                              )}
                            </Button>
                            <Button
                              onClick={() =>
                                handleClickOpenDialogComment({
                                  status: TECHNICAL_INFEASIBILITY,
                                })
                              }
                              variant="contained"
                              classes={{
                                contained: classes.redButton,
                              }}
                            >
                              {t(
                                translations.TechnicalVisitForms.buttons
                                  .technicalInfeasibility,
                              )}
                            </Button>
                            <Tooltip
                              title={
                                <StepsCollectionsText
                                  stepsCollections={
                                    validationState.stepsCollections
                                  }
                                />
                              }
                              placement="right"
                            >
                              <span>
                                <Button
                                  disabled={!validationState.validated}
                                  onClick={() =>
                                    changeStatus({ status: VALIDATED })
                                  }
                                  variant="contained"
                                  classes={{
                                    contained: classes.greenButton,
                                  }}
                                >
                                  {t(
                                    translations.TechnicalVisitForms.buttons
                                      .validate,
                                  )}
                                </Button>
                              </span>
                            </Tooltip>
                          </>
                        )}

                      {!isSupplier && (
                        <Button
                          disabled={
                            activeStep === resources.length - 1 ||
                            !canChangeStep
                          }
                          onClick={handleNext}
                          variant="contained"
                          color="secondary"
                        >
                          {t(translations.TechnicalVisitForms.buttons.next)}
                        </Button>
                      )}
                      {items.technicalVisit.status === PLANIFIED && (
                        <Tooltip
                          title={
                            <StepsCollectionsText
                              stepsCollections={
                                validationState.stepsCollections
                              }
                            />
                          }
                          placement="right"
                        >
                          <span>
                            <Button
                              disabled={!validationState.validated}
                              classes={{
                                contained: classes.greenButton,
                              }}
                              onClick={() => changeStatus({ status: REALIZED })}
                              variant="contained"
                              color="secondary"
                            >
                              {t(
                                translations.TechnicalVisitForms.buttons
                                  .vtAchieved,
                              )}
                            </Button>
                          </span>
                        </Tooltip>
                      )}

                      {items.technicalVisit.status === REALIZED &&
                        (isAdmin || isSuperadmin) &&
                        activeStep === resources.length - 1 && (
                          <Button
                            onClick={() => changeStatus({ status: PENDING })}
                            variant="contained"
                            color="secondary"
                          >
                            {t(
                              translations.TechnicalVisitForms.buttons
                                .sendVerification,
                            )}
                          </Button>
                        )}

                      {(items.technicalVisit.status === VALIDATED ||
                        items.technicalVisit.status === REALIZED ||
                        items.technicalVisit.status === PENDING) &&
                        (isAdmin || isSuperadmin) &&
                        activeStep === resources.length - 1 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            disabled={isCallingApi}
                            onClick={() => generatePdf()}
                          >
                            {t(
                              translations.TechnicalVisitForms.buttons.download,
                            )}
                          </Button>
                        )}

                      {(isAdmin || isSuperadmin) && (
                        <Button
                          onClick={() => duplicateTechnicalVisit()}
                          variant="contained"
                          color="secondary"
                        >
                          {t(
                            translations.TechnicalVisitForms.buttons.duplicate,
                          )}
                        </Button>
                      )}
                    </Box>
                  )}
                  {!technicalVisitsCached[technicalVisitId] && (
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      justifyContent="space-between"
                      margin="11px 0px"
                    >
                      <Skeleton variant="rect" width={121} height={35} />
                      <Skeleton variant="rect" width={121} height={35} />
                    </Box>
                  )}
                </Box>
                {documentsCollections.length > 0 && (
                  <ImagesCollection
                    canEdit={
                      (isBasic &&
                        (items.technicalVisit.status === PLANIFIED ||
                          items.technicalVisit.status === REALIZED) &&
                        items.technicalVisit.technician === currentUser.id) ||
                      isAdmin ||
                      isSuperadmin
                    }
                    resource={{
                      label: resources[activeStep].stepLabel,
                      name: routeResources[resources[activeStep].resource],
                    }}
                    collections={documentsCollections}
                  />
                )}
              </Box>
            )}
          </StepperContext.Consumer>
        )}
      </StepperContext.Provider>
      <DialogComment
        open={dialogComment.isOpen}
        handleClose={handleCloseDialogComment}
      />
    </>
  );
}

const RedFab = withTheme(styled(Fab)`
  &.MuiFab-primary {
    margin-right: 10px;
    background-color: ${({ theme }) => theme.palette.error.main};
    &:hover {
      background-color: ${({ theme }) => theme.palette.error.dark};
    }
  }
`);

const StepsCollectionsText = ({ stepsCollections }) => (
  <>
    {stepsCollections.map((collections: any) => (
      <>
        {collections.map(({ title, files }) => (
          <>
            {title}: {files.map(({ label }) => label).join('; ')}
            <br />
          </>
        ))}{' '}
        <br />
      </>
    ))}
  </>
);
