import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { translations } from 'locales/i18n';
import { appDataSelector } from 'app/selectors';
import { useStepperContext } from '../Context';

import { Box, TextField } from '@material-ui/core';
import { FormikInput } from 'app/components/FormikInput';
import { DocumentDtInterface } from '../types';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { GroupSelect } from 'app/components/GroupSelect';

export const Form = () => {
  const { setFieldValue, values } = useFormikContext<DocumentDtInterface>();
  const { t } = useTranslation();
  const { setValidationSchema, variant } = useStepperContext();
  const { onSmallDevice, lists, isSupplier } = useSelector(appDataSelector);
  const [listsOptions, setListsOptions] = React.useState<any>({});

  const [listKeys] = React.useState([
    'additional_three-phase',
    'triphasé_dt',
    'single_phase',
    'additional_mono-phase',
  ]);
  React.useEffect(() => {
    setListsOptions(
      lists
        .filter((l) => listKeys.includes(l.key))
        .map((l) => ({
          header: l.name,
          subLists: l.sublists?.map((cl) => ({
            value: cl.id,
            displayedValue: cl.name,
          })),
        })),
    );
  }, [setListsOptions, lists, listKeys]);

  React.useEffect(
    () =>
      setValidationSchema(
        yup.object({
          oneline: yup.number().nullable(),
          emission: yup
            .number()
            .nullable()
            .min(0, t(translations.formErrors.minLength, { length: '0' }))
            .max(9999, t(translations.formErrors.maxLength, { length: '9999' }))
            .typeError(t(translations.formErrors.onlyDigits)),
          furtherInformation: yup.string().nullable(),
        }),
      ),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(values).length > 0 && values.hasOwnProperty('oneline') && (
        <>
          <Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
              flexWrap="wrap"
            >
              {!_.isEmpty(listsOptions) && (
                <FormikInput
                  name="oneline"
                  label={t(translations.DocumentDtForms.labels.oneline)}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    options: listsOptions || [],
                    style: { width: '100%', minWidth: '100px' },
                  }}
                  containerStyle={{ flex: '3' }}
                  component={GroupSelect}
                />
              )}
              <FormikInput
                name="emission"
                label={t(translations.DocumentDtForms.labels.emission)}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  style: { width: '100%', minWidth: '150px' },
                  onChange: (e) =>
                    setFieldValue(
                      'emission',
                      e.target.value
                        ? e.target.value.replaceAll(',', '.')
                        : null,
                    ),
                }}
                containerStyle={{ flex: '1' }}
                component={TextField}
              />
            </Box>
          </Box>
          <Box>
            <FormikInput
              name="furtherInformation"
              label={t(translations.DocumentDtForms.labels.furtherInformation)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                rows: 6,
                multiline: true,
                style: { width: '100%' },
              }}
              component={TextField}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
